.app{
    
    .table{
        table-layout: initial;
        word-wrap:break-word;

        svg.icon{
            padding:2px;
            vertical-align: middle;
            margin:0;
            float:none;
        }

        .checkbox, .radio{
            padding-left:0;
        }

        .fullsize-inputs {
            .app-checkbox, .app-radio{
                width:100%;
            }
        }

        &.bt tbody tr td{
            border-bottom:solid 1px @cloudy;

            &:last-child{
                border-bottom:dashed 3px @silver;
            }
        }


        .btn{
            margin-left:5px;

            &:first-child{
                margin-left:0;
            }

            &.btn-success{
                background: green;
                color:@white;
            }
        }



        tr{
            td,th{
                line-height: 20px;
                padding: 10px 15px;
                vertical-align: middle;
            }
        }
        > thead{
            > tr > th{
                color: @white;
                border-bottom: none;
                background: @ink;
                border-right:solid 1px #666;
                font-size:12px;
                font-weight: 500;
                text-transform: capitalize;
            }
        }

       > thead:first-child > tr:first-child > th:first-child {
           border-top-left-radius: @app-design-border-radius;
       }

        > thead:first-child > tr:first-child > th:last-child{
            border-top-right-radius: @app-design-border-radius;
        }

        > tbody{
            > tr{ 
                > td{                    
                    //border-bottom: 1px solid @smoke;
                }
                
                &:last-child{
                    > td{
                        border-bottom: 0px;
                    }
                }
            }
        }
        
        &.table-striped{
            > tbody{
                > tr:nth-of-type(odd){
                    background: @misty;
                }
            } 
        }
        
        &.table-bordered{
            border: 1px solid @smoke;
            
            > tbody{
                > tr{
                    > td{
                        border: 0px;
                        border-bottom: 1px solid @smoke;
                        border-right: 1px solid @smoke;
                        
                        &:last-child{
                            border-right: 0px;
                        }
                    }
                    &:last-child{
                        > td{
                            border-bottom: 0px;
                        }
                    }
                }
            }            
        }
        &.table-hover{
            > tbody{
                > tr{
                    .btn-details, .btn-edit, .btn-delete, .btn-remove-order{
                        color:#000;
                        background: #F6F8FA;
                    }
                    &:hover{
                        background-color:@cloudy;

                        > td{
                            background: inherit;
                        }

                        .btn-details {background: @mid-blue}
                        .btn-edit {background: @mid-orange}
                        .btn-delete {background: @mid-red}
                    }
                }
            }
        }
        &.table-light-header{
            > thead{
                > tr > th{
                    background-color:#f1f4f8;
                    color:@ink;
                    border-right-color:#e8e8e8;
                }

                &:first-child>tr:first-child>th:last-child{
                    border-radius:0;
                }
            }
        }
        &.table-head-custom{
            > thead{
                > tr > th{
                    background: @ink;
                    color: @cloudy;
                }
            }
        }
        &.table-noborder{
            > thead > tr > th,
            > tbody > tr > td{
                border: 0px;
            }
        }
        
        &.table-clean-paddings{
            > thead > tr > th:first-child,
            > tbody > tr > td:first-child{
                padding-left: 0px;
            }
            > thead > tr > th:last-child,
            > tbody > tr > td:last-child{
                padding-right: 0px;
            }
        }

        .btn {box-shadow:none; }

        .horizontal-list-table {
            margin-bottom: 0;

            > tbody > tr > td,
            > tbody > tr > th,
            > thead > tr > td,
            > thead > tr > th{
                border-top: none;
            }

            > thead > tr > th {
                background: none;
                color: @silver;
                letter-spacing: 0.8px;
                border-right:none;
                padding-bottom: 0;
            }

            td span {
                display: block;
                color: @silver;
            }
        }

    }
    
    .block{
        > .table{
            &.table-bordered{
                border-left: 0px;
                border-right: 0px;                
                
                > thead > tr > th,
                > tbody > tr > td{
                    border-left: 0px;                    
                }
                > thead > tr > th:last-child,
                > tbody > tr > td:last-child{
                    border-right: 0px;
                }
            }
            &:last-child{
                margin-bottom: 0px;
                border-bottom: 0px;
            }                        
        }
    }
    
    .table{
        .contact{            
            &.contact-lg, &.contact-xlg, &.contact-single{
                margin-bottom: 0px;
            }
        }
    }
}


.detail-title{font-weight:400}

.dataTables_wrapper{
    div.row:first-child{
        margin-top:15px;
        margin-bottom:15px;
    }
    div.dataTables_info{
        float:left;
        padding-top:20px;
        color:@silver;
    }
}


.table-row-special{
    margin-left: -15px !important;
    margin-right: -15px !important;
}
.table-row-special.row>div[class^=col-]:last-child{
    padding-right:15px !important;
    padding-left:15px !important;
}

#export .dropdown-menu .icon{
    width:18px;
    height: 18px;
    margin-right:8px;
}

.invoice-summary-table{
    td{
        width:150px;

        .input-group .input-group-addon, .form-control{
            border:none ;
            opacity:1;
            padding-left:0;
        }

        .input-group .input-group-addon{
            padding-right:0
        }
    }

    svg{
        width:20px !important;
        height:20px !important;
    }

    .form-group:last-child,.input-group{
        margin-bottom:0 !important;
    }
}

/***** Misc Tables ****/
.notifications-table td[colspan],
#services td[colspan],
.table-nested td[colspan]{
    padding:0 !important;
}

.notifications-table table, #services table, .table-nested table{
    margin-top:0 !important;
    margin-bottom: 0;
}

.notifications-table .date{font-weight: 600; font-size:1.2em}


#invoice-edit-table .form-group:last-child,
#invoice-add-table .form-group:last-child,
.table-payment-add .form-group:last-child{
    margin-bottom:0 !important;
}

#invoice-edit-table .app-checkbox label,
#invoice-add-table .app-checkbox label,
.table-payment-add .app-checkbox label{
    display:inline;
}

/*********************************/


@media (max-width: @screen-md) {
    .table-responsive{
        border: 0px;
    }
}

table{
    &.fixedHeader-floating {
        /*position: fixed !important;*/
        background-color: #4f5467;
        color: #fff;
        padding: 5px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        >thead>tr>th{
            border:none;
        }

        &.dataTable{
            margin-top: 0px !important;
            margin-bottom: 0px !important;
        }

        thead{
            .sorting:after,
            .sorting_asc:after,
            .sorting_asc_disabled:after,
            .sorting_desc:after,
            .sorting_desc_disabled:after{
                bottom:6px !important;
            }
        }

        &.no-footer {
            border-bottom-width: 0;
        }
    }

    &.fixedHeader-locked {
        position: absolute !important;
        background-color: white;
    }

    @media print {
        table.fixedHeader-floating {
            display: none;
        }
    }
}