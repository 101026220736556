/**************** File Manager *******************/
.library-wrapper figcaption {
    height:40px;
    overflow: hidden;
    margin-top:10px;
}

.modal .library-wrapper{
    margin-top: 30px;
}

.library-wrapper .thumbnail.selected{
    border: solid 2px;
}

a.preview{
    background: repeating-linear-gradient(-55deg, #FFF 12px, rgba(0,0,0,0.03) 18px, #FFF 9px, #FFF 20px);
    overflow: hidden;
}

.file-actions button{
    margin-left:5px;
}

.file-actions .btn.btn-clean.btn-default,
.file-actions .btn.btn-clean.btn-info,
.file-actions .btn.btn-clean.btn-warning,
.file-actions .btn.btn-clean.btn-danger{
    border-color:#ccc;
}

.file-actions .btn:hover{
    border-color:transparent !important;
}


.check-all{
    width:50px;
}

.colorpicker-element .input-group-addon i{
    background:repeating-linear-gradient(-55deg, #FFF 12px, rgba(0,0,0,0.05) 18px, #FFF 9px, #FFF 20px)
}

.colorpicker-element .input-group-addon i[style^="background-"]{
    background-image:none;
}