.margin-0{margin: 0px !important;}
.margin-top-0{margin-top: 0px !important;}
.margin-top-5{margin-top: 5px !important;}
.margin-top-10{margin-top: 10px !important;}
.margin-top-15{margin-top: 15px !important;}
.margin-top-20{margin-top: 20px !important;}
.margin-top-30{margin-top: 30px !important;}
.margin-top-35 {margin-top: 35px !important;}
.margin-top-40{margin-top: 40px !important;}
.margin-top-50{margin-top: 50px !important;}
.margin-top-100{margin-top: 100px !important;}
.margin-top-minus-one{margin-top: -1px !important;}

.margin-bottom-0{margin-bottom: 0px !important;}
.margin-bottom-5{margin-bottom: 5px !important;}
.margin-bottom-10{margin-bottom: 10px !important;}
.margin-bottom-15{margin-bottom: 15px !important;}
.margin-bottom-20{margin-bottom: 20px !important;}
.margin-bottom-30{margin-bottom: 30px !important;}
.margin-bottom-40{margin-bottom: 40px !important;}
.margin-bottom-50{margin-bottom: 50px !important;}
.margin-bottom-100{margin-bottom: 100px !important;}

.margin-left-0{margin-left: 0px !important;}
.margin-left-5{margin-left: 5px !important;}
.margin-left-10{margin-left: 10px !important;}
.margin-left-15{margin-left: 15px !important;}
.margin-left-20{margin-left: 20px !important;}
.margin-left-30{margin-left: 30px !important;}
.margin-left-40{margin-left: 40px !important;}
.margin-left-50{margin-left: 50px !important;}

.margin-right-0{margin-right: 0px !important;}
.margin-right-5{margin-right: 5px !important;}
.margin-right-10{margin-right: 10px !important;}
.margin-right-15{margin-right: 15px !important;}
.margin-right-20{margin-right: 20px !important;}
.margin-right-30{margin-right: 30px !important;}
.margin-right-40{margin-right: 40px !important;}
.margin-right-50{margin-right: 50px !important;}

.padding-0{padding: 0px !important;}
.padding-5{padding: 5px !important;}
.padding-10{padding: 10px !important;}
.padding-15{padding: 15px !important;}
.padding-20{padding: 20px !important;}
.padding-30{padding: 30px !important;}
.padding-40{padding: 40px !important;}
.padding-50{padding: 50px !important;}

.padding-left-0{padding-left: 0px !important;}
.padding-left-5{padding-left: 5px !important;}
.padding-left-10{padding-left: 10px !important;}
.padding-left-15{padding-left: 15px !important;}
.padding-left-20{padding-left: 20px !important;}
.padding-left-30{padding-left: 30px !important;}
.padding-left-40{padding-left: 40px !important;}
.padding-left-50{padding-left: 50px !important;}

.padding-right-0{padding-right: 0px !important;}
.padding-right-5{padding-right: 5px !important;}
.padding-right-10{padding-right: 10px !important;}
.padding-right-15{padding-right: 15px !important;}
.padding-right-20{padding-right: 20px !important;}
.padding-right-30{padding-right: 30px !important;}
.padding-right-40{padding-right: 40px !important;}
.padding-right-50{padding-right: 50px !important;}

.padding-top-0{padding-top: 0px !important;}
.padding-top-5{padding-top: 5px !important;}
.padding-top-10{padding-top: 10px !important;}
.padding-top-15{padding-top: 15px !important;}
.padding-top-20{padding-top: 20px !important;}
.padding-top-25{padding-top: 25px !important;}
.padding-top-30{padding-top: 30px !important;}
.padding-top-40{padding-top: 40px !important;}
.padding-top-50{padding-top: 50px !important;}

.padding-bottom-0{padding-bottom: 0px !important;}
.padding-bottom-5{padding-bottom: 5px !important;}
.padding-bottom-10{padding-bottom: 10px !important;}
.padding-bottom-15{padding-bottom: 15px !important;}
.padding-bottom-20{padding-bottom: 20px !important;}
.padding-bottom-30{padding-bottom: 30px !important;}
.padding-bottom-40{padding-bottom: 40px !important;}
.padding-bottom-50{padding-bottom: 50px !important;}

.border-top-0{border-top: 0px !important;}
.border-bottom-0{border-bottom: 0px !important;}
.border-left-0{border-left: 0px !important;}
.border-right-0{border-right: 0px !important;}

.text-default{color: @ink !important;}
.text-muted{color: @pewter !important;}
.text-primary{color: @cloudy !important;}
.text-info{color: @blue !important;}
.text-success{color: @green !important;}
.text-warning{color: @orange !important;}
.text-danger{color: @red !important;}

.text-sm{font-size: @app-layout-base-font-size - 2;}
.text-rg{font-size: @app-layout-base-font-size;}
.text-lg{font-size: @app-layout-base-font-size + 2;}
.text-xlg{font-size: @app-layout-base-font-size + 4;}

.text-light{font-weight: 300 !important;}
.text-regular{font-weight: @app-layout-base-font-weight !important;}
.text-semibold{font-weight: 500 !important;}
.text-bold{font-weight: 700 !important;}

.text-uppercase{text-transform: uppercase !important;}
.text-lowercase{text-transform: lowercase !important;}
.text-capitalize{text-transform: capitalize !important;}
.text-italic{font-style: italic !important;}

.wide{width: 100% !important;}
.visible-mobile{display: none;}

.text-right{
    text-align: right !important;
}
.text-left{
    text-align: left !important;
}

.animate-infinite{
    -vendor-animation-duration: 1s;
    -vendor-animation-delay: 1s;
    -vendor-animation-iteration-count: infinite;
}

.overflow-auto {overflow: auto;}
.no-overflow {overflow: hidden}
.no-padding{
    padding:0 !important;
}
.display-inline-block{display:inline-block}
.display-block{
    display: block !important;
}

canvas:empty{
    display: none;
}

.block-overlay{
    position: absolute;
    width: -webkit-calc(100% - 30px);
    width: calc(100% - 30px);
    height: -webkit-calc(100% - 30px);
    height: calc(100% - 30px);
    background: rgba(255,255,255,0.8);
    z-index: 9999;
    text-align: center;
    display: none;
}

.empty-state{
    width: 40%;
    text-align: center;
    margin: 0px auto;
}
.empty-state img {max-width:100%; height:auto}
.empty-state figcaption {margin:20px; font-weight: 500; font-size:1.2em;}
.align-center{
    margin-left: auto;
    margin-right: auto;
}

.width-100{
    width:100px !important;
}

.bg-white{
    background-color:@white;
}

.bg-misty{
    background-color:@misty;
}

.no-box-shadow{box-shadow:none !important;}
.float-left{
    float:left;
}

// end

@media (max-width: @screen-md) {
    .visible-mobile{display: block;}
    .hidden-mobile{display: none;}
    .float-left-mobile{float: left !important;}
    .float-right-mobile{float: right !important;}
    .clear-mobile{clear: both;}
    .text-left-mobile{text-align: left !important;}
    .text-right-mobile{text-align: right !important;}
}