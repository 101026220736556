.alert-build-style(@name,@background,@color,@border){
    &.@{name}{
        h4{color:@border; }
        svg{ vertical-align: top; margin-right:15px; margin-top:2px}
    }
}

.alert{
    .alert-text{
        display: inline-block;
    }
    
    .alert-icon{
        float: left;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        line-height: 20px;
        text-align: center;
    }
    &.alert-icon-block{
        padding-left: 15px;
        position: relative;
        z-index:2;
        
        .alert-icon{
            position: absolute;
            left: 0px;
            top: 0px;
            min-width: 50px;
            height: 100%;
            padding: 15px;
        }
    }
    
    &.alert-transparency{
        opacity: 0.8;
    }
    strong{font-weight: 600;}

    .alert-build-style(alert-default,@white,@ink,@smoke);
    .alert-build-style(alert-primary,@cloudy,@ink,darken(@cloudy,3%));
    .alert-build-style(alert-info,@light-blue,@ink,@blue);
    .alert-build-style(alert-success,@light-green,@ink, @green);
    .alert-build-style(alert-warning,@light-orange,@ink,@orange);
    .alert-build-style(alert-danger,@light-red, @ink,@red);
    
    &.alert-inside{
        border: 0px;
        .border-radius(0px);
    }
    
    &.alert-dismissible{
        > .close{
            position: absolute;
            right: 10px;
            top: 12px;
            opacity: .4;
            transition:opacity @app-default-transition-speed linear;

            span{
                line-height: 20px;
            }

        }
    }
}

