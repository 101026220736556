@app-tile-basic-bg: @white;
@app-tile-basic-bg-hover: @misty;
@app-tile-basic-font-color: @ink;
@app-tile-basic-title-color: @ink;
@app-tile-basic-sub-color: @pewter;

.app{
    
    .tile-basic{
        float: left;
        width: 100%;
        background: @app-tile-basic-bg;
        color: @app-tile-basic-font-color;
        margin-bottom: 20px;
        position: relative;
        //.border-radius();
        
        .tile-image{
            display: block;
            float: left;
            width: 100%;
            position: relative;
            overflow: hidden;            
            
            img{
                width: 100%;
            }
        }

        .tile-content{
            float: left;
            width: 100%;
            padding: 30px;

            .tile-title{
                display: block;
                margin-bottom: 0px;
                color: @app-tile-basic-title-color;
                position: relative;                
                
                &.tile-title-underlined{
                    margin-bottom: 15px;
                    
                    &:after{
                        content: " ";
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        margin-left: -25px;
                        width: 50px;
                        height: 2px;
                        background: @white;
                        margin-top: 8px;
                    }
                }
                
                &:last-child{
                    margin-bottom: 0px;
                }
                
            }
            a.tile-title{
                font-size: 16px;
                line-height: 26px;
            }
            

            p{
                &:first-child{
                    margin-top: 0px;
                }
            }
        }
        
        .tile-image + .tile-content{
            padding-top: 20px;
        }
        

        &.tile-transparent{
            background: transparent;
            border: 0px;
        }
    }
    
}