
.dropdown,.dropup{
    float: left;
}
.dropdown-menu{
    margin-top: 5px;
    min-width: 220px;
    background: @white;
    border-color: @smoke;
    .box-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));

    > li{
        > a{
            display: block;
            float: left;
            width: 100%;
            padding: 5px 15px;
            line-height: 30px;
            .transition(background @app-default-transition-speed linear);
            
            .label{
                margin-top: 5px;
                
                &.pull-right{
                    position: absolute;
                    right: 15px;
                }
            }
        }

        /* icons support */
        span[class^='icon-'],.fa{
            float: left;
            margin-right: 10px;
            line-height: 30px;
        }
        span[class^='icon-']{
            font-size: 20px;
        }
        .fa{
            font-size: 14px;
        }
        /* end icons support */

        /* checkbox and radio support */
        .app-checkbox, .app-radio{
            float: left;
            width: auto;
            margin: 5px 0;
        }
        /* end checkbox and radio support */

        &.active{
            > a, 
            > a:hover, 
            > a:focus{
                background: @misty;
                color: @ink;
            }
        }

        &.dropdown-header{
            margin-top: 5px;
            padding: 5px 15px;
            line-height: 30px;
            font-size: @app-layout-base-font-size;
            color: @ink;
            font-weight: 600;

            &.highlight{                
                background: darken(@misty,3%);
            }                        

            &:first-child{
                margin-top: 0px;
            }
        }
    }
    
    .divider{
        margin: 5px 0px;
    }
    
    &.dropdown-left{
        left: auto;
        right: 0px;
    }
    
    &.dropdown-form{
        padding: 0px;
        width: 340px;
        
        > li{
            padding: 15px;
            
            span[class^='icon-'],.fa{
                float: none;
                margin-right: 0px;
                line-height: 20px;
            }
        }
    }
}

.popover{
    padding: 0px;
    border: 1px solid @smoke;
    .box-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
    
    > .arrow{
        border-width: 6px;
        
        &:after{
            border-width: 5px;
        }                
    }
    
    &.top{
        > .arrow{margin-left: -5px; bottom: -6px;            
            &:after{margin-left: -5px;}
        }
    }
    
    &.left{
        > .arrow{right: -6px; margin-top: -5px;
            &:after{bottom: -5px;}
        }
    }
    &.right{
        > .arrow{left: -6px; margin-top: -5px;
            &:after{bottom: -5px;}
        }
    }
    &.bottom{
        > .arrow{margin-left: -5px; top: -6px;            
            &:after{margin-left: -5px;}
        }
    }
    
    .popover-title{
        float: left;
        width: 100%;
        padding: 10px 15px;
        line-height: 20px;
        font-weight: 600;
        border: 0px;
        border-bottom: 1px solid @smoke;
        background: @ink;
        color:@cloudy;

        .close{
            font-size: 22px;
            color: @white;
            text-shadow: none;
            opacity: .8;
        }
    }
    .popover-content{
        float: left;
        width: 100%;
        padding: 15px;
    }
}

.modal-style-build(@name, @bg, @color){
    &.@{name}{
        .modal-header{
            background: @bg;
            
            .modal-title{
                color: @color;
            }
        }
    }
}

.modal{
    .modal-dialog{
        margin: 100px auto 0px;

        .heading-line-below {
           /* padding-bottom: 10px;*/
            color: @ink;
            margin: 0;
        }
        
        .modal-content{
            .border-radius(@app-design-border-radius);
            border-color: transparent;
            .box-shadow(0px 0px 0px 10px rgba(0, 0, 0, 0.1));
            .clearfix();
            
            .modal-header{
                border: 0px;
                float: left;
                width: 100%;                
                .border-radius(@app-design-border-radius @app-design-border-radius 0px 0px);
                padding: 22px 30px 0 30px;
                margin-bottom: 30px;
                
                .modal-title{
                    width: auto;
                    font-weight: 600;
                    line-height: 30px;

                    svg.delete{
                        fill:@red;
                    }
                }
            }
            .modal-body{
                float: left;
                width: 100%;
                padding: 0 30px;
                margin-bottom: 40px;
                background:#fff;
                font-size: 15px;
                line-height: 1.6;
                
                .app-heading{
                    background: transparent;
                }
            }
            .modal-footer{
                float: left;
                width: 100%;
                padding: 15px 0;
                background-color: @misty;
                border: solid 1px @smoke;
                text-align: center;
            }
            
        }
        
         .close{
            position: absolute;
            top: 22px;
            right: 30px;
            z-index: 999;
            color: @ink;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            opacity: 0.5;
            .transition(opacity @app-default-transition-speed linear);
            
            [class^="icon-"]{
                font-size: 20px;
            }
            .fa{font-size: 14px;}
            
            &:hover{
                opacity: 0.8;
            }
        }
        
        &.modal-fw{
            width: 94%;
        }
        
        .modal-style-build(modal-primary,@cloudy,@white);
        .modal-style-build(modal-info,@blue,@white);
        .modal-style-build(modal-success,@green,@white);
        .modal-style-build(modal-warning,@orange,@white);
        .modal-style-build(modal-danger,@red,@white);
                
    }    
}
@media (min-width: @screen-sm) {
    .modal-payment {
        width: 450px;
    }
    #md-refund .modal-payment {
        width: 700px;
    }
}

@media(max-width: @screen-xs){
    .variant-modal {
        .modal-dialog {
            width: 90% !important;
            left: 5% !important;
            margin-left: 0 !important;
            padding:15px !important;
        }
        .app-checkbox{margin-top:0 !important;}
    }

    .modal .modal-dialog .modal-content{
        .modal-body{
            margin-bottom:15px;
            padding:0 15px;
        }

        .modal-header{
            margin-bottom:15px;
            padding:15px;
        }
    }
}

/*** Tooltips *****/
[data-tooltip].top:before, [data-tooltip].top:after {
    transform: translateY(10px);
}
[data-tooltip].top:hover:after, [data-tooltip].top:hover:before {
    transform: translateY(0px);
}

[data-tooltip].right:before, [data-tooltip].right:after {
    transform: translateX(0px);
}
[data-tooltip].right:hover:after, [data-tooltip].right:hover:before {
    transform: translateX(10px);
}

[data-tooltip].bottom:before, [data-tooltip].bottom:after {
    transform: translateY(-10px);
}
[data-tooltip].bottom:hover:after, [data-tooltip].bottom:hover:before {
    transform: translateY(0px);
}

[data-tooltip].left:before, [data-tooltip].left:after {
    transform: translateX(0px);
}
[data-tooltip].left:hover:after, [data-tooltip].left:hover:before {
    transform: translateX(-10px);
}

[data-tooltip] {
    position: relative;
}
[data-tooltip]:after, [data-tooltip]:before {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: transform 200ms ease, opacity 200ms;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 99;
}
[data-tooltip]:before {
    content: attr(data-tooltip);
    background: @white;
    color: #000;
    font-size: 12px;
    font-weight: 300;
    padding: 10px 15px;
    border-radius: 5px;
    white-space: pre-wrap;
    text-decoration: none;
    letter-spacing: 0.5px;
    width: 150px;
    line-height: 17px;
    border: solid 1px @ink;
}
[data-tooltip]:after {
    content: '';
    box-shadow: none;
    width:14px;
    height:14px;
    background:#fff;
    transform:rotate(45deg);
    border: 1px solid @ink;
    filter:none;
}
[data-tooltip]:hover:after, [data-tooltip]:hover:before {
    visibility: visible;
    opacity: 0.95;
    //transform: translateY(0px);
    overflow: visible;
}

.info-tooltip[data-tooltip]:before,
.info-tooltip[data-tooltip]:after{
    background:@light-blue;
    border-color:@blue;
}


td:hover [data-tooltip], .item:hover [data-tooltip], .switch:hover [data-tooltip] {
    overflow: visible;
}


[data-tooltip][data-position="top"]:before {
    bottom: 100%;
    left: -130%;
    margin-bottom: 10px;
}

[data-tooltip][data-position="top"]:after {
    bottom: 101%;
    left: calc(50% - 6px);
    margin-bottom: 3px;
    border-top: none;
    border-left: none;
    border-bottom: 1px solid @ink;
}

.info-tooltip[data-tooltip][data-position="top"]:after {
    border-bottom-color:@blue;
}

[data-tooltip][data-position="left"]:before {
    top: -12%;
    right: 100%;
    margin-right: 10px;
}

[data-tooltip][data-position="left"]:after {
    top: calc(50% - 3px);
    right: 100%;
    margin-top: -6px;
    transform: rotate(45deg);
    border-left: none;
    border-bottom: none;
    margin-right: 4px;
}

[data-tooltip][data-position="right"]:before {
    top: -5%;
    left: 100%;
    margin-left: 10px;
}

[data-tooltip][data-position="right"]:after {
    top: calc(50% - 9px);
    border-left: 1px solid @ink;
    border-top: none;
    border-right:none;
    left: calc(100% + 3px);
}

.info-tooltip[data-tooltip][data-position="right"]:after {
    border-left-color:@blue;
}

[data-tooltip][data-position="bottom"]:before {
    top: 100%;
    left: -130%;
    margin-top: 10px;
}

[data-tooltip][data-position="bottom"]:after {
    top: 100%;
    left: 5px;
    margin-top: 3px;
    border-right: none;
    border-bottom:none;
    border-top: 1px solid @ink;
}
.info-tooltip[data-tooltip][data-position="bottom"]:after {
    border-top-color:@blue;
}

.app-navigation-logo-button[data-tooltip][data-position="right"]:before {
    top:9%;
}

.app-navigation-logo-button[data-tooltip]:hover:after,
.app-navigation-logo-button[data-tooltip]:hover:before{
    opacity:1;
}


@media (max-width: @screen-md) {
    .modal{
        padding-left: 15px;
        
        .modal-dialog{
            margin: 50px auto 0px;
            width: 100%;
        
            .modal-content{                
                .box-shadow(none);
                width: 100%;
            }
        }
    }
    
}

.context-help-block[data-style="1"] {
    background: transparent;
    border-radius: 20px;
    height: 20px;
    width: 20px;
    animation: pulse 2s infinite;
    display: inline-block;
}

.context-help-block:before {
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="#00a6ff" viewBox="0 0 24 24"><path d="M12 .5C5.659.5.5 5.659.5 12S5.659 23.5 12 23.5 23.5 18.341 23.5 12 18.341.5 12 .5zm0 4c1.378 0 2.5 1.122 2.5 2.5S13.378 9.5 12 9.5 9.5 8.378 9.5 7s1.122-2.5 2.5-2.5zm3 15h-5c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5h.5v-3H10c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5h4c.276 0 .5.224.5.5v5.5h.5c.827 0 1.5.673 1.5 1.5s-.673 1.5-1.5 1.5z"/></svg>');
    cursor: pointer;
}

label + .context-help-block[data-style="1"]{position: relative;  top: 5px;}

label + .context-help-block[data-style="2"]{  position: relative;  top: 2px;}