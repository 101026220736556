.loader-regular {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(0,0,0,.3);
    border-radius: 50%;
    border-top-color: @orange;
    animation: spin 1s ease-in-out infinite;
}
@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

.loader-alt {
    position: absolute;
    top: 50%;
    left: 50%;
    margin:-25px 0 0;
    width: 50px;
    height: 50px;
    border:dashed 2px @smoke;
    border-left-color: @orange;
    border-radius: 50%;
    animation: spinner 1200ms infinite linear;
}

@keyframes spinner {
    0% {transform: rotate(0deg);}
    25% {transform: rotate(90deg); }
    50% {transform: rotate(180deg);}
    75% {transform: rotate(270deg); }
    100% {transform: rotate(360deg);}
}

.spin {
    animation: spinner 1200ms infinite linear;
}


