.stat-footer{
    &::after{
        content: "\20E0";
        font-size: 1.2em;
        margin-left: 2px;
    }

    &.text-success::after{
        content: "\25B2";
        font-size: 1em;
    }

    &.text-danger::after{
        content: "\25BC";
        font-size: 1em;
    }
}

#canvas_bar{
    margin-bottom:30px;
}

.reports-table{
    display: none;
}
.gm-style canvas {
    display: inline-block;
}

/*** Misc ***/
#custom-fields td {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
}

#driver_license_scan{
    height:0; border:none; padding:0;
}

ul.dashboard-stats{min-height: 80px}