.notifications-controls{
    .dropdown-menu{
        width: 380px;
        margin-left: -180px;
        margin-top: 15px;
        padding-bottom: 0;
        padding-top: 0;
        border-top: none;
    }

    .arrow-up {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
        position: absolute;
        left: 50%;
        top: -10px;
    }

    li.listing-item{
        border-bottom:dashed 1px #e3e8f1;
        position:relative;

        &:last-child{
            border-bottom:none;
        }
    }


}


.notification-tabs {
    border: none;
    border-bottom: solid 2px #4f5467;
    margin-bottom: 10px;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    ul{float:right}

    li{
        float:none;
        display:inline-block;
        list-style:none;
        width :initial;
    }

    h4{
        display:inline-block;
        margin-bottom: 0;
        margin-top: 5px;
        font-size: 18px;
    }

    a {
        padding: 6px 12px;
        font-size: 16px;
        border-radius: 4px;
        display: block;
        font-weight: 500;
        color: #7f8fa4;

        &.active{
            background: #1d78f0;
            color: #fff;
        }
    }

    &:hover, &:focus, &:active{
        text-decoration: none;
    }
}

.all-notifications > a {
    /*color:#4f5467;*/
    font-size:15px;
    &:hover{
        text-decoration: none;
    }
}


.dropdown-menu.listing{
    padding:0;
}
li.listing-item a{
    padding:15px 5px;
    display: block;
    color:#4f5467;
    font-weight: 500;
}
.listing-item-date{
    font-size:12px;
    font-weight: normal;
    color:#7f8fa4;
    display: block;
}

.listing-item-desc{
    margin: 5px 0 0 0;
    font-size:1em;
}

li.listing-item:hover, .app .all-notifications{
    background-color:#f4f6f7;
}

.event-listing > li.all-notifications{padding:0}
.event-listing > li.all-notifications a{display: block; padding: 15px}

.app .all-notifications svg.icon {
    margin-right:10px;
    height:16px;
    width:16px;
}


.global-listing > li, .event-listing > li {padding:15px;}
.listing-globals li.listing-item{padding: 10px 0;}

.record-count.animated{
    animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
}
@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
        box-shadow: 0 0 0 0 rgba(0,0,0, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
        box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
}
