.app {
  .contact {
    position: relative;
    display: block;
    float: left;
    width: 100%;
    text-decoration: none;
    line-height: 20px;
    padding: 10px 0 10px 30px;

    >img {
      width: 20px;
      position: absolute;
      left: 0;
      top: 10px;
    }

    >.contact-controls {
      top: 10px
    }
  }
}

.user-initials{
  display: inline-block;
  background: @ink;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  color: @white;
  font-size: 1.2em;
  letter-spacing: 2px;
}