body {overflow-x:hidden !important;}
.tox-statusbar__branding{display:none !important;}
.open-button.open{z-index: 99; position: relative; fill:#4f5467; color:#4f5467}

.hide-dms-check {
    .app-radio label,
    .app-checkbox label {
        padding-left: 0;
        cursor: text;
        span {
            &:after,
            &:before {
                display: none !important;
            }
        }
    }
    [type="submit"],
    [type="button"],
    .tox-tinymce {
        display: none;
    }
    .tinymce_basic { display: block !important; }
}
/************** Settings ***************/
#api-settings-list th{
  font-weight:500;
  width: 150px;
  color:@ink;
}

.table.working-hours .app-checkbox{
  width:initial;
}

.reveal-pass{cursor:pointer}


/********** E-mail Templates ***********/
.short-tags-list th{
  width:100px;
}
.short-tags-list li a {
  margin-bottom:5px;
  display: inline-block;
}


/*************** Users *****************/
.user-show-pic {
  float: left;
  text-align: center;

  img {
    max-width:80px;
  }
}


.user-image{
    li:last-child{

    .icon{
      fill:@orange;
    }

    a{
      color:@orange;
    }
  }

  .icon {
    width:20px;
    height: 20px;
    margin-right:15px;
  }

  img{
    border-radius:@app-design-border-radius;
  }

}

.modal{
  overflow: scroll;
}
.include-sets-check{margin-left:10px}
/************* Unsorted **************/
.delivery-details ul{padding-left:10px; list-style: none}
.delivery-details li{line-height: 24px;}
.task-files-wrapper li {list-style: square; margin-left:20px; float:none}
.task-block .app-checkbox+p{margin-top:30px}
.dtr-data, #api_key-value,
#products_table td,
.listing-events .listing-item{
  word-wrap: break-word;
  white-space: initial;
  word-break: break-all;
}
.radio-image-wrapper{justify-content:center}
.form-group.inline-form-group{margin-left:-15px; margin-right:-15px}
.letter-icon {
  color: #1f1f1f;
  font-size: 25px;
  margin-right: 14px;
  margin-left: 1px;
  padding: 2px;
  font-weight: 300;
  position: relative;
  top: 3px;
}

.daterangepicker option.disabled, .daterangepicker td.disabled{
  color:@cloudy !important;
}


/************* Permissions **************/
#in-role:empty{
  display:none;
}
#permissions-list{
  margin-top:15px;
}

.app-navigation nav{
  background:@white;
}
.app-navigation.scroll{

  touch-action: auto;
}
/************* File Manager *************/
a.preview {
  height: 120px;
  text-align: center;
  background: #e5ebf1;

  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnail a.preview>img {
  max-height:120px;
}

.library-wrapper .thumbnail{
  box-shadow:0 1px 4px 0 rgba(0,0,0,.1);
  border:none;
}
#media_lib_modal .thumbnail h4 {
  word-wrap: break-word;
}

#drag_area {
  display: none;
  background: rgba(255, 255, 255, 0.8);
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.thumbnail h4{
  word-wrap: break-word;
}

.remove-file svg{fill:red; color:#fff; cursor: pointer; width:20px; height:20px}

/************** Contracts ****************/
.contract-preview{
  height: 200px;
  overflow-y: scroll;
  display: none;
  position: relative;
}
.heading-clarification{
  color:#7f8fa4;
  display:block;
  //padding-left:40px;
}


/************** Inventory ****************/
.pdf {
  margin-bottom: 10px;
}

table a {
  cursor: pointer;
}

#inventory img {
  width: 50px;
  height: 50px;
}

/**************** Orders ******************/
.pr-count {
  width: 50px !important;
}

.remove-order {
  cursor: pointer;
}

.order-status-filter label {
  text-transform: lowercase;
}

.dates-info{
  display:inline-block;
  //width:90px;
  vertical-align:top;
}

.dates-info-divider{
  width: 50px;
  vertical-align: top;
  text-align: center;
}


.repair-items-form .app-checkbox input[disabled]{
  position: relative;
  width: 0;
  height: auto;
}

/************** Icon Selector **************/
.fip-bootstrap.icons-selector .selector-button{background-color:@white; background-image:none !important;}
.fip-bootstrap.icons-selector .selector-button{width: 24px;}

.mCSB_inside>.mCSB_container{margin-right:0 !important}

.number-rfloated + .input-group-btn-vertical>.btn{
  margin-left:0 !important;
}
input.number-rfloated {
  border-top-right-radius:0 !important;
  border-bottom-right-radius:0 !important;
}


#order-products .input-group.bootstrap-touchspin {
  width:60px;
}

/**************** TinyMCE ******************/

.mce-tinymce {
  box-shadow: none !important;
  border-radius: @app-design-border-radius;
}

.mce-branding{
  display:none !important;
}

.nm20{margin-top:-20px}
/**************** Full Calendar ****************/

.app{
  .fc-state-default{
    background: #f6f8fa;
    border: solid 1px @silver;
    text-shadow:none;
  }

  .fc button{
    height: 40px;
    padding: 0 1em;
    box-shadow: none;
    color: #333;
  }

  .fc-state-active{
    background:@cloudy;
    box-shadow:none;
  }

  .fc-button-group .fc-button-primary, .fc .fc-button-primary{
    background: #f6f8fa;
    border: solid 1px @silver;
    text-shadow:none;
    &:hover,  &:disabled{
      background: #f6f8fa;
      color: inherit;
      border-color: @silver;
    }
  }
  .fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active{
    background:@cloudy !important;
    color: #333;
    box-shadow:none !important;
    border-color: @silver;
  }

  .fc .fc-button-primary:focus, .fc .fc-button-primary:not(:disabled).fc-button-active:focus,
  .fc .fc-button-primary:not(:disabled):active:focus{
    box-shadow:none !important;
  }


}

.fc-timeline{
  .fc-cell-text{
    font-size: 1.2rem;
    letter-spacing: 0.6px;
  }

  .fc-head .fc-cell-content {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  th{
    background: #f6f8fa;
    text-transform: uppercase;
  }

}

.fc-unthemed .fc-resource-area  td{
  border:none;
  background-color: #f6f8fa;
  border-bottom: solid 1px #e5ebf1;
  padding:8px;
}

.fc-time-area td.fc-widget-content {
  padding: 8px 0;
}

.fc-widget-content td a, td.fc-resource a{
  color: @ink;
  font-weight: 700;
}

.app .fc-unthemed td{
  border-color:#e5ebf1;

  &.fc-today{
    background-color: #f6f8fa;
  }
}
.app .fc-theme-standard td{
  border-color:#e5ebf1;
  a{
    color: #4f5467;
  }
  &.fc-datagrid-cell{
    background-color: #f6f8fa;
    border: none;
    border-bottom: 1px solid #e5ebf1;

  }
  &.fc-day-today{
    background-color: #f6f8fa;
  }
}

.app .fc-resource-area .fc-cell-content{
  padding-left:8px;
  padding-right:8px;
}


.fc-license-message{
  display:none !important;
}

td.fc-resource-area{
  width:36%;
}

svg.icon.delete{
  fill:@red !important;
}

.no-shadow {
  box-shadow:none !important;
}

.blurred {
  filter:blur(2px);
}


.menu-item-title{white-space: pre-wrap; word-break: break-word;}


.company-logo-email{
  width:auto;
  max-height:80px;
}

.group-row{background: @misty}
#order-picking-slip .product-name{
  width: 500px;
  word-break: break-all;
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.picking-square .app-checkbox{
  margin:-24px 0 0 0;
}

@keyframes pulse2 {
  0% {
    box-shadow: 0 0 0 0 rgba(0,0,0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(49,191,13, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(49,191,13, 0);
  }
}

.app-navigation-horizontal ul ul ul{list-style:square; margin-left:50px}
.app-navigation-horizontal ul ul ul a {color:#4f5467; line-height: 2em;}
.app-navigation-horizontal .letter-icon{font-size: 18px; top: 0; padding: 1px; font-weight: 400;}

//.btn-choose-variant{
//  animation:pulse 2s infinite;
//  display:inline-block;
//}
#ps-history-table td{font-size:12px !important;}

@media (min-width:768px){
  .app-navigation-horizontal ul ul ul{margin-left:20px}
  .app-navigation-horizontal .letter-icon{margin-right:4px; margin-left: 0; }
  .app .app-navigation-horizontal nav>ul>li>ul ul>li a.active{background:transparent; color:#ff8000}
  .app .app-navigation-horizontal nav>ul>li>a{margin-bottom:1px}
  .app .app-header .app-header-buttons{padding-top:3px}

}

/**************** Payments Page *********/
.credit-label {
  display: inline-block;

}

table thead .app-checkbox {
  vertical-align: super;
}

.app-scanner-panel{
  position: fixed;
  top: 140px;
  right: -620px;
  width: 620px;
  z-index: 1001;
  transition: all .1s linear;
}

.app-scanner-panel.reveal {
  right: 0;
  max-width:90%;
}

.app-scanner-panel.reveal .app-scanner-panel-trigger {
  border:none;
}

.app-scanner-panel .btn-link{
  font-weight: bold;
  font-size: 1.8em;
  padding: 0;
  color: #4f5467;
}

.app-scanner-panel-trigger {
  position: absolute;
  left: -39px;
  top: 2px;
  width: 40px;
  height: 40px;
  background: #fff;
  border: 1px dashed #ff8800;
  border-right:none;
  line-height: 41px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  transition: all .1s linear;
  box-shadow: -2px 0 3px 2px rgba(0,0,0,.05);
  border-radius: 3px 0 0 3px;
  color: #4f5467;
}
.user-scan-filter li{
  padding: 0 15px;
}

.product-sku{
  text-transform: uppercase;
  font-size:0.8em;
  color:#7f8fa4;
  display:block;
  font-weight:500;
}

#scanned-products-table .pro-img{
  float:left;
  margin-right:15px;

}

#scanned-products-table .pro-img img{
  border-radius:4px;
}

.delete-scanned-list{
  font-size: 16px;
  color:#f04e51;
  font-weight: 500;
}

.crm-call-plans .radio-img {
  width: 250px;
  height: auto;
  display: flex;
}

.crm-call-plans .radio-image-wrapper {
  display: block;
}
.crm-call-plans {
  margin-top:20px;
  display:flex;
  justify-content: center;
  font-size:1.1em;
}

.radio-image-wrapper{
  width:100%;
  background-color:#fff;
  box-shadow:0 4px 14px rgba(0,0,0,0.05);
}

.radio-image-wrapper svg{
  //height: 140px;
  width: auto;
  margin: 20px auto;
  display: block;
}

.crm-call-plans .plan-price{
  display: block;
  color: #ff8800;
  font-size: 0.8em;
  font-weight: 500;
  float: right;
  margin-top: 5px;
}

.plan-title{
  border-bottom: solid 2px #456467;
  font-size: 1.5em;
  padding-bottom:5px;
}

.plan-description{
  font-weight: 400;
  background: #f4f6f7;
  padding: 12px;
  border-radius: 4px;
}

.plan-options{margin-top: 20px;}
.plan-options li {font-weight: normal; line-height: 2em; float:none;}
.plan-options b {font-size: 1.2em}

.add-note, .amount-info{position: relative; z-index:2}

/**************** Mobile ****************/

@media (max-width: 768px) {

  .app .row div{
      &[class*=" col-xs-2"]{
        width:16.66666667%;
      }

      &[class*=" col-xs-4"]{
        width:33.33333333%;
      }

      &[class*=" col-xs-5"]{
        width:41.66666667%;
      }

      &[class*=" col-xs-6"]{
        width:50%;
      }

      &[class*=" col-xs-8"]{
        width:66.66666667%;
      }
  }

  .flex-grid.one-thirds > div {
      flex: 0 0 calc(50% - 15px);
      margin-right:15px;

      &:nth-child(2n+2){
        margin-right: 0;
      }
  }

  .tab-compact.nav.nav-tabs>li>a{
    border-bottom:solid 1px @misty;

    svg{
      display:inline-block;
      margin:0 10px 0 0 ;
    }
  }

  .nav>li>a{
    padding:15px 30px;
  }

  .nav.nav-tabs>li{
    width:100%;
  }

  .app .horizontal-nav-logo{display:none}
  .app .app-navigation-horizontal.state-open+.app-heading-page{margin-top:20px}

  ul.kb-category-listing li{
    width:50%;
  }

  span.group-name{font-weight: bold}

  .app .pagination{margin:0 !important;}

  .btn-help {
    padding:2px 10px !important;
    svg{display:none}
  }

  .status-block{
    margin-bottom:80px !important;
    margin-left: 30px;
    margin-right: 30px;
  }

  .app .invoice-summary-table  div[class^=col-md]{
    margin-bottom:0;
  }

  .invoice-summary-table #discount, #add_tax_rate{
    margin-bottom:15px;
  }

  .timeline-bar{
    height:auto !important;
  }

  .app .pricing-info div[class^=col-md],
  .app .sidepanel-header div[class^=col-md]{
    margin-bottom:0;
  }

  .app #sidebar-search-block{
    margin-top:0;
    margin-bottom:0;
  }
  .table-division {margin-bottom:15px}

  .modal {
    padding-left: 15px !important;
    padding-right: 15px;
  }
  .modal .modal-dialog{
    margin:30px auto 0;
  }

  .app #kb-settings-form .form-group > div{
    margin-bottom:0
  }

  .app-scanner-panel .nav>li>a {
    padding-left: 15px;
    padding-right: 15px;
  }

  .app-scanner-panel .nav.nav-tabs>li>a svg{
    width:20px;
    height:20px;
  }



  .btn{margin-bottom:0}
  #add-payment-method.margin-top-35,
  #add_purchase_orders .margin-top-40,
  #form_av .margin-top-40,
  #coupons-form .margin-top-35{
    margin-top:0 !important;
  }
  #filter_clients.margin-left-15 {
    display: inline-block;
    width: auto;
  }

  #client_table_filter,
  div.dataTables_wrapper div.dataTables_length{
    text-align:left;
  }

  div.dataTables_wrapper div.dataTables_filter,
  #payment_table_length,
  #client_address_table_length,
  #client_contact_table_length{
    text-align: right;
  }

  div.dataTables_wrapper div.dataTables_filter label{
    width:100%;
  }
  .task-files-wrapper {border-top: dotted 1px #ccc;
    margin-top: 10px;
  }

  .task-files-wrapper ul{margin-top:10px}

  #invoices-table_wrapper,
  #variants_wrapper .toolbar1, .btn-save{
    margin-bottom: 20px;
  }
  #purchase_orders_wrapper .toolbar1{text-align: center; margin-bottom:20px}
  #products_wrapper .unassigned{margin: -35px 0 20px -5px;}

  .flex-grid.one-thirds>div{flex:0 0 100%}

  //#table_filter{margin:20px 0}
  #canvas_bar + #table_wrapper .dt-buttons{float: none}

  .info-panel-data li{width:100%}
  .order-info-panel li:last-child{border-bottom:none}
  .contract-actions a, .contract-actions button {margin-bottom:10px}
  #calendar .fc-center{margin-top:15px}
  .create-block, .expire-block {text-align: center; margin-bottom: 15px}


  .mile-wrapper .col-md-4.padding-0, .mile-wrapper .col-md-8.padding-0, .mile-wrapper .col-md-2.padding-0{margin-bottom:0}
  .tab-compact.tab-dashboard.nav.nav-tabs>li{width:50%}
  .radio-img{width:calc(50% - 8px)}
  .radio-img:nth-child(2n+1){margin-right:0}
  .order-options .app-radio{margin-bottom:10px}

  .form-group.inline-form-group{margin-left:-10px; margin-right:-10px}
  .view-style{float:right}
  .mobile-mb-15 {margin-bottom:15px}
  #tasks_length{margin-top:20px}

  /** -- This is a fix for iPhones, zomming in and not zooming out --**/
  input, textarea, select{font-size:16px !important;}

}

@media (max-width: 480px) {
  .selectize-control.item.single{min-width:200px}
  .app .app-header .app-header-buttons .user-info{display:none}
  .tab-compact.nav-tabs>li>a,
  .toolbar,
  .panel-footer{
    text-align: center;
  }
  .work-orders-status .app-radio label{
    margin-bottom:10px;
  }

  .app .app-header .app-header-search>input{
    width:32px;
    line-height: 30px;
    margin-top: 4px;
    font-size:13px !important;
    height: 32px;
  }

  .app .app-header .app-header-search:after{
    left: 23px;
    top: 14px;
    font-size: 32px;
  }

  .main-payment-form #add_method{
    display: none;
  }

  .user-profile-list .list-group-item{
    border-right:none;
    border-bottom:dotted 1px @smoke;
    margin-bottom:0
  }

  #buffer_time_type{
    padding:1px;
  }

  #client_table_wrapper .toolbar, #orders_wrapper .status-filter{
    text-align: center;
  }

  #orders_wrapper .status-filter{
    margin-bottom:20px;
  }

  #orders_wrapper .toolbar{
    float:none;
  }

  .order-info-panel{
    flex-direction:column !important;
  }

  .notifications-controls .dropdown-menu {
    width: 250px;
    margin-left: calc(50% - 20px);
  }
  .notifications-controls .arrow-up{left:10px}


  .status-title{width:initial; left: calc(50% - 23px); font-size: 10px;}
  .status-block{margin-left:10px; margin-right:10px}
  .product-name, .product-variant-name{width:120px !important;}
  .product-actions{float:none !important; display: block !important;}
  .product-desc{margin-right:15px}
  .container.availability-page{padding-left:0; padding-right:0}
  #products_filter,
  #add-ons_filter,
  #table1_filter{
    margin:0 15px;
  }

  #coupons-form .app-radio{margin-top:5px}
  .modal-footer .btn-close{margin-top:20px}
  #print-quarantine,
  #workorder-button,
  #back-to-stock{
    width:100%;
    margin-bottom:15px;
  }
  .tab-compact.tab-dashboard.nav.nav-tabs>li>a{padding:0 15px}

  #barcode-management-area #print_btn{width:100%; margin-top:15px}

  td.fc-resource-area img,  td.fc-resource-area .product .img{width:30px ; height:30px}
  td.fc-resource-area{width:50%}
  .pricing-info label, .total-not label{margin-right:10px; margin-left:10px}
  .contract-actions a, .contract-actions button {width:100%; margin-bottom:10px}
  .user-initials{width:30px; height:30px; line-height: 30px; font-size:0.8em}
  .log-user a{font-size:11px}

  #calendar .status-dropdown,  #calendar .division-dropdown{width:50%}
  #calendar .status-dropdown .btn-group,  #calendar .division-dropdown .btn-group,
  #calendar .status-dropdown .btn, #calendar .division-dropdown .btn,
  #calendar .fc-toolbar .fc-left,
  #calendar .fc-header-toolbar .fc-right
  {
    width:100%;
  }
  #calendar .division-dropdown{width:calc(50% - 0.75em)}
  #calendar .fc-header-toolbar .fc-right{margin:20px 0}
  .order-panel .order-submit{margin-bottom:15px}
  #api_key-value{margin-bottom:10px; display: block}
  .radio-img{width:100%; margin-right:0}
  .cgen-options .app-radio.inline{margin-bottom:10px}


  /**** variants.css ***/
  .order-info-panel li{
    padding:15px 0 !important;
    //border-bottom: 1px solid @smoke;
    border-right:none !important;
  }

  .flex-grid {
    flex-direction: column;

    &.one-thirds > div {
      flex: 0 0 100%;
      margin-right:0;
    }
  }


  .app-navigation-horizontal + .container{
    margin-top:60px;
  }
  .app-navigation-horizontal + .app-heading-page{
    margin-top:50px;
  }



  div.dataTables_wrapper div.dataTables_length select{
    width:65px;
  }
  div.dataTables_wrapper div.dataTables_length label{font-size:9px !important; font-weight: 500 !important;}

}

@media screen and (max-width: 320px){
  .app .app-header .app-header-buttons .user-image{
    width:30px; margin-right: 7px;
  }
  .app .app-sidepanel{width:300px}
}


.cropping-image-wrap{padding: 2px; border: 1px solid #e3e8f1;}
.cropping-preview-wrap{padding: 2px; border: 1px solid #e3e8f1; float: left;}
.cropping-preview{float: left; overflow: hidden; background: #f7f7f7; width: 250px; height: 250px;}
.has-feedback input[type="number"].form-control {padding:6px 12px}

#md-delete-warning[data-status="1"] p.lead:not(.canceled-order),
#md-delete-warning:not([data-status="1"]) .canceled-order {
  display: none;
}

.email-truncate{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
  display: block;
  position: relative;
  z-index: 99;
}

.email-truncate:hover{
  overflow: visible;
}
iframe{max-width:100%}
.client-info-panel .client-info a{color: #4f5467;}
.responsive-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.ach-payment-block + .billing-address[data-billing-required="0"]{display:none !important;}

.input-group.disabled .input-group-addon{border-color: #fafafa; background: #fafafa; opacity:.5;}
/* IE11 only */
@media all and (-ms-high-contrast:none)
{
  *::-ms-backdrop, .modal-content .modal-body {
   width: auto !important;
  }

  .bootstrap-touchspin .input-group-btn-vertical{width:auto}
}

.daterangepicker{font-family: inherit !important; font-weight: 500}
.daterangepicker .calendar-table td, .daterangepicker .calendar-table th{
  width: 35px !important;
  height: 28px !important;
  line-height: 28px !important;
  font-size: 14px !important;
}

.phones .input-group {
  z-index: 100;
}
#iisfocus{opacity:0}

.company-1186 #restore-order{display:none}

.barcode-label .subtitle{
  float: none !important;
}
.app .barcode-label .intval{
  text-align: left;
}
.app .barcode-label .intval img{
  display: inline-block;
  margin-right: 3px;
}

#image-dropzone.hover{
  background-color: #e3e8f1;
}

@media  (min-width:992px) and (max-width:1230px){
  .app div.dataTables_wrapper div.dataTables_length label {
    text-overflow: initial
  }
}
.header-top-help{
  width: 35px;
  text-align: center;
}
.header-top-help a{
  display: block;
}
/****************** SVG Icon Colors ******************/

//.cls-1{fill:#cecece};
.cls-1{fill: #efe6df;}

svg:hover .cls-1,
.flex-grid .block:hover .cls-1,
.nav > li > a:hover .cls-1, .nav > li > a.active .cls-1,
.app-navigation.app-navigation-style-light nav > ul > li a:hover .cls-1{fill: #efe6df;}

//.cls-2{fill:#868499;}
.cls-2{fill:#f2a35e}
svg:hover .cls-2,
.flex-grid .block:hover .cls-2,
.nav > li > a:hover .cls-2, .nav > li > a.active .cls-2,
.app-navigation.app-navigation-style-light nav > ul > li a:hover .cls-2{fill:#f2a35e}

.cls-3{fill:#fff;}

//.cls-4{fill:#76706a /*#3c4c64;*/}
.cls-4{fill: #f26666;}
svg:hover .cls-4,
.flex-grid .block:hover .cls-4,
.nav > li > a:hover .cls-4, .nav > li > a.active .cls-4,
.app-navigation.app-navigation-style-light nav > ul > li a:hover .cls-4{fill: #f26666;}

//.cls-5{fill:#bfbfbf}
.cls-5{fill: #e2dad5;}
svg:hover .cls-5,
.flex-grid .block:hover .cls-5,
.nav > li > a:hover .cls-5, .nav > li > a.active .cls-5,
.app-navigation.app-navigation-style-light nav > ul > li a:hover .cls-5{fill: #e2dad5;}
.app .app-heading-page svg{vertical-align: baseline;}
.dropdown-menu a svg{width:20px; height:20px; margin-right:6px}
.feather-move{width:20px; height:20px;}
.sep-block{ padding: 15px}
.rec-checkbox.half-split{display: block}
.rec-checkbox.half-split span span{width:50%; text-align: center}
.delivery-block .radio-img{width:23%; margin-right: 2%; font-weight: 500; height: auto}
.delivery-block .radio-img img{max-width:25px; margin:0 10px 0 0}
.flip-x + .radio-image-wrapper img{transform: scaleX(-1);}
.form-heading.order-panel-title{color:#4f5467; font-weight: 500}
.order-panel-title:before{height:2px; /*background:#4f5467;*/ bottom:-8px}
.header-top-help{
  width: 35px;
  text-align: center;
}
.header-top-help svg{    fill: #fff;
  width: 35px;
  height: 35px;
  background: #fff;
  border-radius: 50%;
}

#order-settings .form-horizontal .control-label{
  font-size:14px; font-weight: 500;
}

.inner-group{
  padding: 10px 0;
  margin: 0 0 30px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.inner-group-block{
  padding: 0 22px;
  border-right: 1px solid #e3e8f1;
  flex-grow: 1;
  flex-basis: 0;
}
.inner-group-block h4{
  border-bottom: dotted 2px #ddd;
  padding-bottom: 5px;
}
.inner-group-block:last-child{border-right: none}

.inner-group-4cols .inner-group-block{
  width: 25%;
}

@media (max-width: 768px){
  .inner-group-4cols .inner-group-block{
    width: 50%;
  }
  .delivery-block .radio-img{width:100%; margin-right:0;}
}

@media (min-width: 768px) and (max-width: 1170px){
  .inner-group-4cols .inner-group-block{
    width: 33.3%;
  }
  .delivery-block .radio-img{width:48%}
  #serialized_items_modal .modal-dialog { width: 1000px;}
  .sr-items-table td { font-size: 13px; }
}

@media (min-width: 1170px){
  #serialized_items_modal .modal-dialog { width: 1100px;}
}

.inner-group-2cols .inner-group-block{
  width: 50%;
}

.form-horizontal .inner-group-block .control-label{font-weight:500}
.app-sidebar-left-minimized .app-navigation-logo{padding-left:0}

/* List Info Component */
.order-info-panel {
  list-style-type: none;
  padding: 10px 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.order-info-panel.settings-section  > li {font-size:inherit}


.order-info-panel > li {
  padding: 0 22px;
  border-right: 1px solid #e3e8f1;
  flex: 0 1 auto;
  float: none;
  min-height:77px;
  font-size:16px;
  font-weight:500;
}

.order-info-panel > li:first-child {
  padding-left:0;
}

.order-info-panel > li:last-child {
  border: none;
  padding-right:0;
}

.order-info-panel li:last-child a{
  color: #4f5467;
  text-decoration: none;
}

.order-info-panel li h5,
.info-footer{
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 13px;
}

.info-panel-icon svg.feather{
  width:16px;
  height:16px;
  margin-right:6px;
}

.info-panel-data{padding-left:0}

.title-with-svg svg{
  position: relative;
  top: 5px;
}
.inner-group-block:first-child{padding-left:0}
.inner-group-block:last-child{padding-right:0}
/*.panel-body{padding:15px 30px}*/
.workflow-row{border-left: dashed 2px #e3e8f1;
  margin-left: 9px !important;
}

.dropdown-title{background:#4f5467; color:#fff; font-size: 1.1em; font-weight:500; padding: 8px 15px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  margin-bottom: 10px; }

.dashboard-days .dropdown-menu{padding-top: 0; border: none; min-width: 280px}
.mini-card-block {display: inline-block;}
.mini-card-block a{
  display: block;
  border: solid 1px #e3e8f1;
  padding: 5px;
  margin: 4px 0 4px 2px;
  width: 56px;
  text-align: center;
  line-height: normal;
  font-weight: 400;
  color: #4f5467;
  border-radius: 4px;
  background: #fafafa;
}

.mini-card-block a:hover {
  color: #4f5467;
  font-weight: 500;
}

.list-inner-title{
  color: #4f5467;
  font-weight: 500;
  margin-top:10px
}
.dashboard-days label{color: #4f5467; font-weight: 500;}
.dashboard-days .dropdown-menu>.active>a{background: transparent}
.dashboard-stats .chart-container{width:initial}
.dashboard-stats .chart-container svg{
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 50%;
  position: relative;
  right: -6px;
  bottom: 5px;
  max-width:initial;
}
.chart-container svg.feather-trending-up {
  color: #00c363;
  background: #dff8eb;
}
.chart-container svg.feather-trending-down {
  color: #f04e51;
  background: #ffe7ed;
}

.app .app-header{
  background-color: #4F5467;
  background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23555a6f' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%235b6177' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%2361677f' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23676d87' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%236D748F' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23676d87' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%2361677f' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%235b6177' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23555a6f' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%234F5467' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
}
.app .app-header .app-header-buttons .btn-settings svg {
  color: #ff8800;
}
.app .app-header .record-count{background-color:#ff8800}
.header-top-help svg {
  fill: #fff;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  color: #4f5767;
}
.app .app-header .app-header-buttons .btn-settings svg {
  margin-top: 10px;
  color: #fff;
}

.app .app-header .record-count{
  width: 22px;
  height: 22px;
  line-height: 22px;
  /*background-color: #fff;*/
  color:#fff;
}

@media screen and (min-width: 768px){
  .app .app-header .app-header-search > input{width:220px; transition:none;}
}

.app .app-header .app-header-search:after{
  transform: none;
  color: #fff;
  width: 22px;
  fill: #fff;
  filter: invert(1);
  content:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>');
}


body a, body a:hover {color:#00a6ff; /*font-weight: 500*/}
.pagination > li > a, .pagination > li > span{color:#4f5467}

.due-sum{
  border-left: solid 1px #e3e8f1;
  padding-left: 20px;
  font-size: 13px;
  font-weight: 500;
}
.float-right{float: right !important;}

.due-sum span {font-size: 16px; font-weight: bold;}
.notification-tabs a.active{ background: #1d78f0;color: #fff;}
.global-listing > li, .event-listing > li{padding:0 15px 15px 15px}
.global-listing > li.all-notifications{padding:0}
.global-listing > li.all-notifications a {padding:15px; display: block}
/*.listing-item p{font-weight: 500}*/

.table-narrow-wrapper {margin-top:20px}
.tab-content > .table-narrow-wrapper {margin-top:0}
.table-narrow-wrapper .dt-button-down-arrow{font-size: 12px; padding-left: 6px;}
.table-narrow-wrapper .buttons-colvis {padding: 7px 14px;}
.table-narrow-wrapper .form-heading{margin-bottom:25px}
div.dt-button-collection button.dt-button, div.dt-button-collection div.dt-button, div.dt-button-collection a.dt-button{
  background:#fff;
}
div.dataTables_wrapper div.dataTables_length label {
  font-size: 12px;
  color: #4f5467;
  font-weight: 500;
  text-transform: none;
}

.invoice-due-stats{
  background: #eef7fd;
  padding: 4px;
  border-radius: 4px;
}
.invoice-due-stats em{font-style: normal}
.invoice-due-stats div:first-child{border-left:none; padding-left:0}
@media screen and (max-width: 480px){
  .invoice-due-stats{    display: flex;
    flex-direction: row;
    padding: 10px 4px;
    margin-top: 5px;
  }
  .invoice-due-stats .mini-card-block {width: 30%; padding-left: 0 !important;}
}

.table-rows-dashed.table > tbody > tr > td{border-top:none; border-bottom:1px dashed #e3e8f1}
.app .table-rows-dashed.table-bordered > tbody > tr > td{border-style:dashed}
.app .table-rows-dashed.table-bordered{border:none}
.dashboard-orders ul.nav>li.active a .tab-icon, .dashboard-title .title-icon {
  background-color: #eef7fd;
  box-shadow: none;
}
.get-details svg, tr.shown svg{width: 20px; height: 20px; color: #4f5467;}
.heading-line-below:before {
  bottom: -10px;
  height: 2px;
  background: #4f5467;
}
.nav.nav-tabs > li.active > a {
  border: none;
  border-bottom: solid 2px #4f5467;
  background: #eef7fd;
  color: #4f5467;
}
.nav.nav-tabs > li > a{border-bottom-width: 2px}
.nav.nav-tabs > li > a:hover{border-bottom-color: #4f5467;}
.activity-item-author svg{width:18px; height:18px}
/*.toolbar{margin-bottom:10px}*/


.form-inner-heading{
  font-weight: 500;
}
.form-inner-heading.heading-line-below:before{
  background: #e3e8f1;
}
.summary-list-block {display: flex;  flex-flow: column wrap;  align-content: space-between; }
.summary-list-block div{width:50%}
.payment-details .block{margin-bottom:0}

.btn.btn-primary.btn-clean.btn-sm {
  padding: 4px 10px;
}
.btn.btn-sm svg {
  /*margin-right: 5px;*/
  width: 18px;
  height: 18px;
}

.inner-group-block li{border-bottom:dashed 1px #e3e8f1; line-height: 36px}
#orders-by-address_wrapper > div{margin:0 -10px}

.dataTables_wrapper select.input-sm {
  height: initial;
  line-height: initial;
}

.dataTables_wrapper .input-sm {
  height: initial;
  font-size:initial;
  padding: 6px 12px;
}

.inner-arrow{
  float: left;
  margin-left: 15px;
  top: 5px;
  position: relative;
}
//.radio-image-wrapper{display:block}
.blacklist-label label{font-weight: bold; color:#f04e51}
#group-by-category svg{width:9px; height: 13px}
.feather-truck{stroke-width:1px; stroke: #4f5467;}
#form_statuses .radio{padding-left: 0; margin-bottom: 15px}
#form_statuses label{text-transform: lowercase}
#form_statuses label::first-letter{text-transform: uppercase}
.info-panel-data .inner-desc, .info-panel-data li{float:left}
.info-panel-data .inner-desc{word-break: break-word;
  width: calc(100% - 100px);}

.info-panel-data{float:none}
.info-panel-data li{
  border-bottom: solid 1px #efefef;
  padding: 8px 0;
  margin-bottom: 0;
}

.info-panel-data li li {padding:initial; border-bottom:initial}
.info-panel-data li:last-child{border-bottom:none}
.info-panel-data .inner-title{
  color: #7f8fa4;
  width:100px;
  font-weight: normal;
}
.info-panel-data .inner-desc .label{margin-bottom: 0}
.info-panel-data .inner-desc a{color:#4f5467}
.app .feather{max-width:25px}
.header-top-help .feather{max-width: 40px}
.min-width-120{min-width: 120px}


@media screen and (min-width: 1025px) and (max-width: 1200px){
  .order-info-panel > li {width:33.333%; margin-bottom:20px}
  .order-info-panel{flex-wrap: wrap}
}

@media screen and (max-width: 1024px){
  .info-panel-data .inner-title, .info-panel-data .inner-desc, .info-panel-data .label{float:none}
  .info-panel-data .label{display: inline-block;}
  .order-nav-panel svg{max-width:20px}
  .order-nav-panel .tab-semi-compact.nav.nav-tabs > li > a{padding:10px 20px}
  .info-panel-data .inner-title{display:block; width:100%}
  .order-info-panel{flex-wrap:wrap; flex-direction:row}
  .order-info-panel > li {width:50%}
}

@media screen and (max-width: 767px){
  .order-info-panel > li {margin-bottom: 20px;}
}

div.dt-button-collection button.dt-button{border-bottom:dotted 1px #e3e8f1}
.list-group-item-title {display:block; color:#7f8fa4}
.list-group-item-desc{font-weight: 500}
.modal .modal-dialog .heading-line-below.form-inner-heading{margin-bottom:15px}

.sub-setting-block{padding-left: 20px;
  border-left: dashed 1px #e3e8f1;
  margin-left: 10px;
}

.fill-the-rest .app-checkbox{border: dashed 2px #e3e8f1;
  padding: 20px;
  font-weight: 500;
}
.fill-the-rest .app-checkbox label{font-weight: 500}
.working-hours .form-group:last-child{margin-bottom:0}
.dropzone-previews img{margin: 0 auto;}
.dropzone-previews {text-align: center}
.form-inner-heading .app-checkbox{margin-top:0}
.open-divisions svg{
  display: block;
  width: 25px;
  height: 25px;
  cursor:pointer;
}
div.dataTables_wrapper div.dataTables_length select{margin-left:0}
.modal-header .nav.nav-tabs > li > a{padding: 5px 25px}
.modal-header .nav.nav-tabs > li > a svg{width:20px}
.add-on-item > hr {border: dashed 2px #e3e8f1;}


.search-wrapper {
  margin: 0 15px;
  position:relative;
}
.search-box {
  position: relative;
  padding: 6px;
  width: 100%;
  border: none;
  outline: 0;
  border-bottom: 1px solid #a4a6b0;
}

.search-box:focus {

}
.search-close-icon {
  border:1px solid transparent;
  background-color: transparent;
  display: inline-block;
  vertical-align: middle;
  outline: 0;
  cursor: pointer;
}
.search-close-icon:after {
  content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 1;
  right: 5px;
  top: 7px;
  margin: auto;
  padding: 2px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
.search-box:not(:valid) ~ .search-close-icon {
  display: none;
}

.feather-info {fill:#4f5467; color:#fff;}
.icon.icon-30{width:30px; height: 30px}


.nav.nav-tabs.nav-tabs-rounded > li.active > a{border-bottom:none;}
div.dataTables_wrapper div.dataTables_length label{
  color: #4f5467;
  font-weight: 500;
  text-transform: capitalize;
}

.attachment-wrapper{
  border: dashed 0.14em #a4a6b0;
  border-radius: 4px;
  padding: 20px 20px;
  text-align: center;
}

.sub-text{
  color: #7f8fa4;
  display: block;
  font-size:0.75em;
  font-weight: normal;
}
.custom-control-label{font-weight: 600; margin-bottom:5px; display:inline-block}
.iti{width:100%}
.open-button{display: block}
.open-button svg{width:24px ; height:24px}

.order-info-panel.desc-width-150 .info-panel-data .inner-title{width:150px}
.order-info-panel.desc-width-150 .info-panel-data .inner-desc{width:calc(100% - 150px)}
.working-hours-inner tbody td{font-weight: normal !important; font-size: 12px}
.panel-title {
  font-size: 18px;
  font-weight: 500;
  width: auto;
}
.panel-default > .panel-heading {background-color: transparent; border-bottom: solid 2px #e3e8f1;}
.btn.btn-default.active{
  background: #ebeff1;
  color: #444859;
  border: solid 1px #a4a6b0;
}
.docs-pn-panel .btn.btn-icon{
  width: 36px;
  height: 36px;
  line-height: 36px;
  padding: 0;
}

.panel-toggled button span {transform: rotate(180deg);}
.heading-inner-title{font-weight:500; margin:0; position: relative; top: 5px;}
.is-default-variant{margin-right: 5px}
.table-narrow.font-size-9 td{font-size:.9em}
.panel-footer:empty{display: none}
.tab-compact.tab-dashboard.nav.nav-tabs > li > a{color:#4f5467}
.tab-compact.tab-dashboard.nav.nav-tabs > li > a svg{color:#7f8fa4}
#delete-confirmation .modal-title svg{
  position: relative;
  top: 6px;
  width: 20px;
  height: 20px;
}
.override-inline-checkbox .app-checkbox.inline, .override-inline-checkbox .app-radio.inline{width:100%}
.override-inline-checkbox .app-checkbox{margin-bottom:15px}
.flex-grid .block:hover .block-icon {
  background: #eef7fd;
  border-right-color: #eef7fd;
}
.full-width{width:100% !important;}
.text-white{color:#fff !important;}
.switch span {
  background-color: #ffe7ed;
  border: 1px solid #ffe7ed;
}
.switch.switch-sm span{
  border-radius:4px;
}
.switch input+span:before {
  color: #ffe7ed;
  font-size: 12px;
}
.switch span:after {
  //background-color: #f04e51;
  border-radius: 4px;
}

.switch input:checked+span{
  background-color:#dff8eb;
  border-color:#dff8eb;
}

.switch input:checked+span:before {
  filter: invert(1);
  content:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>')
}
.switch.switch-sm input:checked+span:after{
  background-color:#00c363;
}

.switch input:indeterminate + span{
  background-color:#fef1dc;
}

.switch input:indeterminate+span:before{color:#fef1dc}
.switch input:indeterminate+span:after{background-color:#fc6223}

.sortable .dd{max-width:100%}
.sortable.active * {
    user-select: none;
}
.sortable .dd-item{float:none; width: initial}
.sortable .dd-handle {
  height: 40px;
  background: #f4f6f7;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  cursor: move;
  border: 1px solid #e3e8f1;
}

.dd-handle svg{
  width: 18px;
  height: 18px;
  color: #a4a6b0;
  margin-top: 5px;
}
.dd-handle:hover svg{color:#4f5467}

.dd-content{
  height: 40px;
  padding: 10px 15px 10px 50px;
  color: #4f5467;
  font-weight: 500;
  font-size:1.1em;
  border: 1px solid #e3e8f1;
  background: #fff;
  border-radius: 5px;
  margin-bottom:10px;
}
.dd-content .pull-right{
  position: relative;
  top: -4px;
  right: -6px;
}
#nested-cont{margin-top:15px}


/* ************************** Dec 23, 2023 **************************** */
.is-builder{position: relative;
  border: solid 1px #a4a6b0;
  border-radius: 4px;
}

.radio-image-container {display: flex; width: 100%;}
.radio-image-wrapper{display:flex}
.radio-img span:not(.radio-image-wrapper){display:initial}
.radio-img > span:not(.radio-image-wrapper){display:none}
.radio-title small {
  display: block;
  font-weight: 400;
  font-size: 12px;
  color: #7f8fa4;
}

.radio-title{font-weight: 500; font-size: 14px}
.radio-image-only img + .radio-title{display: none !important;}

.radio-fixed-height-60 .radio-img{height: 60px}
.radio-img {height:initial; flex:1}
.radio-img img{max-height: 32px;}
.radio-image-wrapper img + span {display:block; margin-left:10px}

.delivery-block .radio-img {height:60px}

#transportation-type0 + span svg .arr-fwd,
#transportation-type0 + span svg .arr-bck,
#transportation-type1 + span svg .arr-fwd,
#transportation-type2 + span svg .arr-bck,

.multiple-trans-type[value="0"] + span svg .arr-fwd,
.multiple-trans-type[value="0"] + span svg .arr-bck,
.multiple-trans-type[value="1"] + span svg .arr-fwd,
.multiple-trans-type[value="2"] + span svg .arr-bck
{stroke:red}

.inner-categories li{display: flex; align-items: center;align-content: space-evenly;
  flex-wrap: wrap;
  justify-content: space-between;
}
.invoice-due-stats{float:right}

@media screen and (max-width: 1024px){
  .invoice-due-stats{float:none; margin-top:10px}
  .payment-filter-form .form-group:last-child{margin-bottom:0}
  #add-payment-form  .form-group:last-child{margin-bottom:0}
  .order-info-panel > li:nth-child(3){padding-left:0}
  .availability-page .timeline-actions{white-space: initial;width: 33%;}
}

@media (max-width: 991px) {
  .clearfix-sm {
    clear: both !important;
  }
}

@media (max-width: 768px){
  #client_communication .nav.nav-tabs > li {
    width: 25%;
  }
  .nav.nav-tabs-rounded > li > a, .nav.nav-tabs-rounded{
    border-radius: 5px;
  }
  #communication_nav_tabs .nav.nav-tabs > li > a{text-align: center}

  #communication_nav_tabs .nav.nav-tabs > li > a svg {display: block; margin: 5px auto 0 auto;}
  .payment-details {flex-wrap: wrap}
  .dashboard-orders .nav.nav-tabs > li{width:fit-content}
  .dashboard-orders .nav > li > a {padding: 15px 20px}
  .clearfix-xs{clear:both !important;}
  .contract-actions .btn{width: 48%; text-align: left}
  #fileupload{width:48%}
  .contract-actions .btn.fileinput-button{width:100%}
  .calendar-filters > div{width:48%; margin-bottom:10px}
  .color-coding > li{margin-bottom:20px}
  .xs-margin-bottom-15 {margin-bottom:15px}
  .xs-margin-top-15 {margin-top:15px}
  .xs-margin-top-10 {margin-top:10px}

}

.invoice-summary-table{text-align:right}
.clearfix {clear:both}
.dataTables_filter label{display:block}
::placeholder, .form-control::placeholder {color:#7f8fa4; font-size: 14px; opacity:1; font-weight: 500;}
::-ms-input-placeholder, .form-control:-ms-input-placeholder { /* Edge 12 -18 */
  color: #7f8fa4;
  font-size: 14px;
  font-weight: 400;
}
.toolbar a + select.form-control{
  width:auto;
  display: inline-block;
}
.app-checkbox {margin:0}
.dt-buttons button{margin-right:5px}
.st-button-down-arrow{position: absolute;
  right: 8px;
  top: 11px;
  color: #e3e8f1;}

.form-horizontal .control-label{font-size: 14px;}
.open-button.open{z-index:2}

.order-panel-title:after {
  content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"/></svg>');
}

.product-meta-info {
  padding:0;
  margin-top:5px;
  clear:both;
  //padding-right: 150px;
}
.product-meta-info li{
  float: left;
  display: block;
  font-size: .8em;
  line-height: 16px;
  width: 32%;
  max-width: 100px;
  margin-right: 1%;
  margin-bottom: 6px;
}

@media screen and (min-width:1171px) and (max-width:1359px){
  .order-table-cont .product-meta-info li{
    width:48%;
  }
  .order-table-cont .product-desc{width:55%}
  .order-table-cont .product-cont{min-width:300px}
  .order-table-cont .order-tr > div:nth-child(1),
  .order-table-cont  .order-header div:nth-child(1){width: 40%;}
  .order-table-cont .order-header div:nth-child(2){width:12%}

}
.order-table-cont .product-actions * {margin-bottom:5px}

.product-meta-info li.product-options{
  width:100%;
}

.product-meta-info.av-meta-info li{
  width: 23%;
  margin-right: 2%;
}

#inventory .product-desc{width:56%}

/*.new-product .product-meta-info li{line-height: 3.5em;}*/
.product-meta-info li:last-child{border-bottom:none}
.product-meta-info li:empty{display: none}
.product-meta-title{width:80px; display:block; color: #7f8fa4;}
.product-meta-value{display:inline-block}
.label.text-warning, .label.text-primary, .label.text-success, .label.text-danger, .label.text-default{padding:0}
.new-product .product-type svg{width:12px; height:12px; position: relative; top: 3px; margin-left:4px; color:#fc6223}
.new-product .product-type .label-success{border: solid 1px #00c363;}
.new-product .product-type .label-danger{border: solid 1px #f04e51;}
.new-product .product-type .label{color:#4F5467}

.app .table.table-mini{table-layout:fixed !important;}
.app .table.table-mini tr th,  .app .table.table-mini tr td{padding: 2px 4px;}
.product-options .table{margin-top:15px}

.availability-table .table > tbody > tr > td{line-height: initial}
.product-desc{width:62%; flex-grow: 1}
.product-meta-info .label{text-wrap:initial}
.product-meta-info .label em{ text-transform: capitalize; font-style: normal;}
.trans-action-button-group button{margin-bottom:10px}

.delivery-block .radio-image-wrapper {
  justify-content: left;
}

.delivery-block .radio-image-wrapper svg{
  margin: 20px 10px 20px 0;
}

.order-block svg{width:20px; height:20px}
.btn-help svg{vertical-align: middle}

/*.order-info{background:#f4f6f7}*/
.label-overbook, .label.overbooked {
  padding-left: 0;
  background: #f04e51;
  color: #fff !important;
  font-size: 8px;
}

.overbooked-line{background-color:#ffe7ed !important;}

.pricing-info .control-label {padding-top: 6px;}
.pricing-info .remove-fee {float: right;}

.order-group.mixed-date-group .group-col:first-child{    width: 45%;}
.order-group.mixed-date-group .group-col:nth-child(2) {
  width: 40%;
}
.order-group.mixed-date-group .group-col{justify-content: space-evenly}
.remove-order-fee{position:relative; float:right; top:5px; box-shadow:none;}
//.app .product-type label.label, .app .product-type span.label{
//  padding: 0;
//  color: #4f5467 !important;
//  font-weight: 500;
//  background: transparent !important;
//}

.app .panel {
  box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.04);
}

.order-logs .user-initials{background-color:#f4f6f7; color:#4f5467; border:none}
.user-initials{border: solid 1px; border-radius: 5px;}

@media (min-width: 768px){
  .lead {
    font-size: 19px;
  }
}
.card-fee-block, .request-card-fee-block, .share-request-card-fee-block,
.ach-fee-block, .request-ach-fee-block, .share-request-ach-fee-block {
  clear: both;
  padding: 10px;
  border: dashed 1px #e3e8f1;
  border-radius: 5px;
  margin-bottom: 15px;
}

.scanners-menu:empty{display:none}

.info-list{
  list-style-type: none;
  padding: 10px 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.info-list li {
  padding: 0 22px;
  /*border-right: 1px solid #e3e8f1;*/
  flex: 0 1 auto;
  float: none;
}

.info-list li span{
  font-weight: normal;
  color:#7f8fa4;
  display:block
}

.dependency-markup{
  position: relative;
  padding-left: 30px !important;
}
.dependency-markup::before, .dependency-markup::after {
  content: "";
  position: absolute;
}
.dependency-markup::before {
  border-top: 2px dashed #a4a6b0;
  top: 10px;
  width: 9px;
  height: 0;
  left: 16px;
}

.dependency-markup::after {
  border-left: 2px dashed #a4a6b0;
  height: 25px;
  width: 0;
  top: -14px;
  left: 10px;
}

.float-none{float:none}
.product-notes{padding-left:67px}
.plugin-remove_button, .remove-single{color:#f04e51}

.rentopian-branding{margin:15px 0; text-align: center; position: relative;top: 10px; clear:both}
.rentopian-branding a{color:#ff8800; }

@media screen and (min-width: 961px){
  //.add-new-address, .add-new-contact{margin-top:35px}
  .tbd-delivery, .tbd-billing{margin-top:40px}
  .add-new.add-new-address, .client-contact-row .add-new-contact{margin-top:35px}
}
.dt-button-down-arrow{font-size: 0; color: transparent;}
.dt-button-down-arrow::after{content: "\25BE"; font-size: 14px;color: #4f5467; padding-left: 4px}


.app-checkbox.styled-checkbox,
.app-radio.styled-radio{
  float: none;
  margin: 10px 10px 10px 0;
  width: 120px;
  height: 120px;
  display:inline-block;
  text-align: center;
}

.app-radio.styled-radio.styled-radio-vertical,
.app-checkbox.styled-checkbox.styled-checkbox-vertical{
  width: 160px;
  height: 50px;
}

.styled-radio label,
.styled-checkbox label {
  background: #fff;
  border: 1px solid #edf1f5;
  border-radius: 4px;
  display: inline-block;
  width:100px;
  height: 100px;
  margin-right: 15px;
  padding-left: 0;
  font-weight: 500;
}

.app-checkbox.styled-checkbox span b,
.app-radio.styled-radio span b{font-weight: 500}

.app-radio.styled-radio.styled-radio-vertical span b,
.app-checkbox.styled-checkbox.styled-checkbox-vertical span b{padding-left:35px}

.styled-radio.styled-radio-vertical label span,
.styled-checkbox.styled-checkbox-vertical label span{justify-content: normal}

.app-radio.styled-radio.styled-radio-vertical label,
.app-checkbox.styled-checkbox.styled-checkbox-vertical label {
  width: inherit;
  height: inherit;
}

.styled-radio label span,
.styled-checkbox label span {
  display:flex;
  justify-content: center;
  align-items: flex-end;
  border: 1px solid #e3e8f1;
  border-radius: 4px;
  cursor: pointer;
  height: 100%;
  padding: 12px;
  position: relative;
  transition: all .3s ease-in-out 0s;
  background-color: #fff;
  box-shadow: 0 4px 14px rgba(0,0,0,.05);
  width: 100%;
}

.app-checkbox.styled-checkbox label span:before,
.app-radio.styled-radio label span:before {
  background: transparent;
  border: 1px solid #e3e8f1;
  content: " ";
  height: 20px;
  left: calc(50% - 10px);
  position: absolute;
  top: 14px;
  width: 20px;
  z-index: 1;
}

.app-checkbox.styled-checkbox.styled-checkbox-vertical label span:before,
.app-radio.styled-radio.styled-radio-vertical label span:before{
  left:15px;
  top:13px;
}

.app-checkbox.styled-checkbox input[type=checkbox]:checked+span:after {
  top: 16px;
  transform: rotate(45deg);
  width: 6px;
  left: calc(50% - 3px);
}
.app-checkbox.styled-checkbox.styled-checkbox-vertical input[type=checkbox]:checked+span:after{
  left:22px;
}

.app-checkbox.styled-checkbox input[type=checkbox]:checked+span{
  border-color: #00a6ff;
  background-color: #edf1f5;
}

.row.dependency-markup:after{left:18px;}
.row.dependency-markup:before{left:19px}
.row.dependency-markup > div:first-child{margin-top: -8px;}
.ac-item strong{font-weight: 500}

.compact-mode .product-meta-info{padding:10px}
.compact-mode .ac-item,
.compact-mode .icons-block{
  display:inline-block;
}
.compact-mode .av-table-cols {
  padding: 10px 15px;
  text-align: center;
  height:55px;
}

.compact-mode .ac-item{margin-bottom:0 !important;}

.product-cont{
  display: flex;
  align-items: center;
}

//.compact-mode .product-cont{justify-content: space-between;}

#order-table td{position: relative}
#order-table.compact-mode td{padding:3px 10px !important; }
#order-table.compact-mode  .product-actions{padding-top:6px}
#order-table .btn-pis {
  top: 18px;
  right: 60px;
}

.compact-mode.availability-table .dataTable td.unselectable .product-actions{
  top:2px;
}

.compact-mode.availability-table .dropdown-menu{position: relative; box-shadow: none;}

.compact-mode.availability-table .av-table-cols{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.compact-mode #inventory img,
.compact-mode .product-image{
  height: 40px;
  width: 40px;
}

.compact-mode .product-image img {
  height: 40px;
}

.compact-mode .table td.unselectable{
  padding: 7px 15px!important;
}

.btn-pis{
  width: 16px;
  height: 16px;
  display: inline-block;
  line-height: 16px;
  padding: 0;
  font-weight: bold;
  font-style: italic;
  font-family: serif;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  right: -10px;
}

.order-group .group-col:first-child{width:28%;}
.order-group .group-col:nth-child(2){width:60%;}

.product-notes.dropdown-menu{padding:15px}
.btn-pr-notes{position: absolute;
  top: 15px;
  right: 90px;
}
.s-res-cat-item{margin-bottom:10px}
.app-navigation-horizontal .menu-collapse{background: transparent}
.header-search-wrapper{
  float: left;
  margin-top: 9px;
}
.header-search-wrapper input{border-radius: 4px}
.menu-labels-heading + div .sortable .dd-handle{height:100%}
.switch.switch-sm span{text-align:left}
code{
  color: #4f5467;
  background-color: #eef7fd;
}
.cf-module-title{
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 0;
  padding-top: 15px;
  clear:both;
}
#show_custom_fields_container h6:first-child{
  padding-top:0;
}
.product-meta-info li.product-duration{white-space:normal}
.product-meta-info li.product-notes{padding-left:0; width:100%; white-space: normal;}
.transaction-top-panel.nav.nav-tabs>li>a svg{display:block; margin: 0 auto 5px auto}

.transaction-top-panel{
  background: #676D87;
  border-radius: 4px;
}
.transaction-top-panel.nav.nav-tabs>li>a{
  padding: 15px 22px;
  border-right: solid 1px #4f5467;
  color: #f4f6f7;
  border-radius:0;
  position:relative;
}

.transaction-top-panel.nav.nav-tabs>li.active>a,
.transaction-top-panel.nav.nav-tabs>li:hover>a{
  color:#4f5467;
  border-right:none;
  padding-right:23px;
}
.transaction-top-panel.nav.nav-tabs>li:last-child a {border-right:none}

@media screen and (max-width: 961px){
/*  .product-meta-info.av-meta-info li{
    width:100%;
    margin-right:0;
  }*/
  .product-meta-info {
    padding-right: 0;
  }
}

.radio-inline .app-radio{
  display: inline-block;
  margin-right: 20px;
  float: none;
  width: auto;
}

.radio-inline .app-radio:last-child{margin-right:0}

.buttons-columnVisibility.dt-button-active::before,
.checkbox-filter-badge::before{
  content:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="green" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg>');
}

.checkbox-filter-badge::before{
  filter: brightness(0) contrast(1) invert(1);
  position: absolute;
  left: -3px;
  top: -2px;
}

.order-group .group-col:first-child{
  width:30%;
}

.order-group .group-col:nth-child(2) {
  width: 58%;
  justify-content:start;
  padding:0 15px 0 0 ;
}
.order-group .group-col:nth-child(2) > button{
  margin-right:10px;
}

.hide-set-item-icon, .split-item{
  position: relative;
  z-index: 2;
}
.dtrg-group {
  background: #f4f6f7;
}
.set-show-table .product-checks{margin-top:20px}
.set-show-table .product-checks .app-checkbox{margin-bottom:8px}
.toolbar-sync:empty{display: none}
.delivery-block[style="display: none;"] + .form-inner-heading{
  display:none;
}

.sets-carousel .carousel-control.left svg, .sets-carousel .carousel-control.right svg{
  color:#4f5467;
}

.sets-carousel .carousel-indicators li{
  border-color:#4f5467;
}
.sets-carousel .carousel-indicators .active{
  backgound-color:#4f5467;
}

.remove-image svg{
  width: 18px;
  height: 18px;
  color: #f04e51;
}
#modal-vehicle-list > div{
  border-bottom: solid 1px #eee;
  padding-bottom: 5px;
  padding-top: 5px;
}

#modal-vehicle-list > div:last-child{padding-bottom:0}
#modal-vehicle-list > div:first-child{padding-top:0}


#report-applied-filters{
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  border-bottom: solid 1px #eee;
  border-right: solid 1px #eee;
}

#report-applied-filters li{
  border-top: solid 1px #eee;
  border-left: solid 1px #eee;
  padding: 10px;
  flex: 1 1 calc(16.6% - 20px);
  box-sizing: border-box;
  display: flex;
  /*height: 60px;*/
  font-weight: 500;
  flex-direction:column;
  line-height: 1.8em;
}

#report-applied-filters li:empty,
#report-applied-filters:empty{
  display:none;
}

#report-applied-filters li span{
  color:#7f8fa4;
  display:block;
  font-size:12px;
}

/*** Experimental Modern Design Overrides **/

.modern-design .search-box,
.modern-design .app-navigation nav,
.modern-design .app-navigation.app-navigation-style-light,
.modern-design ul.dashboard-stats>li,
.modern-design .app .block.block-alt,
.modern-design .app .app-heading-container{background: #edf1f5;}

.modern-design .app-navigation.app-navigation-style-light,
.modern-design ul.dashboard-stats>li,
.modern-design .container-tab,
.modern-design .panel-default,
.modern-design .app .block.block-alt,
.modern-design .app .app-heading-container{box-shadow:none /*background: #E9F8E3*/}
.modern-design .dashboard-stats .stat-title{font-weight: 500; color:#4f5467}
.modern-design .container-tab,
.modern-design .panel-default{border:solid 1px}
.modern-design .app .app-container,
.modern-design{background:#fff}