.menu-collapse {
    padding: 10px 10px 10px 20px;

    span {
        font-weight: 600;
        color: rgb(164, 166, 176);
        cursor: pointer;
        text-transform: uppercase;
    }

    .menu-less {
        display: none;
    }

    .menu-more {
        display: inline-block;
    }

    + li {
        clear:both;
    }

    &.more{

        .menu-more {
            display: none;
        }

        .menu-less {
            display: block;
        }
    }
}

/***** Horizontal Lines ******/
hr{
    border-top-color: @smoke;

    &.thick {
        margin-top: 18px;
        margin-bottom: 18px;
        border: 0;
        border-top: 2px solid @black;
    }
}

tr.totals{
    border-top: solid 2px @black;
}



svg {
    pointer-events: none;

    &.none{
        fill:@silver;
        margin-right: 4px;
    }
}

.list-group-item a {
    color: @ink;
}

.has-child-selected svg{
    fill: @green;
}
.open>.dropdown-toggle.btn-default.btn-clean{
    border: 1px solid @mid-blue;
}

.dropdown-menu .divider {
    background-color: @smoke;
}

.input-group.readonly .input-group-addon,
.row.readonly .input-group-addon{
    background: @misty;
    border:none;
}

.logo{height:40px}