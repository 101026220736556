.app{
    // preview feature
    .app-preview-video{
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 25px;
        height: 0;            
        float: left;
        width: 100%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border:none;
        }
    }    
    // end preview feature
}