ul.menu-labels-heading{
    background: @ink;
    padding: 10px;
    margin-bottom: 0;
    border-radius:4px 4px 0 0;
}

.menu-labels-heading li{
    font-weight: 500;
    float:none;
    color:@misty;
}

.menu-labels-heading{
    li:first-child{
        width:68px;
    }

    li:nth-child(2){
        width:175px;
    }

    li:nth-child(3){
        width:160px;
    }

    li:nth-child(4){
        width:150px;
    }

    li:nth-child(5){
        width:155px;
    }

    li:nth-child(6){
        width:150px;
    }
}

.menu-collapse {
    background: #4f5467;
    padding: 0;
    text-align: center;
    border-top: dashed 1px #fff;
}
.menu-collapse span {
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 13px;
    letter-spacing: 1px;
}
.menu-collapse .menu-less {
    display: none;
}
.menu-collapse .menu-more {
    display: inline-block;
}