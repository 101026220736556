.clearfix{
    zoom:1;

    &:before, &:after{
        content:"";
        display:table;
    }

    &:after{
        clear: both;
    }
}

.text-shadow(@string: 0 1px 1px rgba(0, 0, 0, 0.5)) {
    text-shadow: @string;
}
.box-shadow(@string) {
    box-shadow:         @string;
}
.shadow(@x: 0, @y: 1px, @blur: 2px, @spread: 0, @alpha: 0.25) {
    box-shadow:	@x @y @blur @spread rgba(0, 0, 0, @alpha);
}
.inner-shadow (@x: 0, @y: 1px, @blur: 2px, @spread: 0, @alpha: 0.25) {
    box-shadow: inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
}

.box-sizing(@type: border-box) {
    -webkit-box-sizing: @type;
    -moz-box-sizing:    @type;
    box-sizing:         @type;
}

.border-radius(@radius: 5px){
    border-radius:         @radius;
}

.border-radiuses (@topleft: 0, @topright: 0, @bottomright: 0, @bottomleft: 0) {
    border-top-right-radius:            @topright;
    border-bottom-right-radius:         @bottomright;
    border-bottom-left-radius:          @bottomleft;
    border-top-left-radius:             @topleft;
}

.opacity(@opacity: 0.5) {
    opacity: 		@opacity;
}

.gradient (@startColor: #eee, @endColor: white) {
    background-color: @startColor;
    background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
    background: -webkit-linear-gradient(top, @startColor, @endColor);
    background: -moz-linear-gradient(top, @startColor, @endColor);
    background: -ms-linear-gradient(top, @startColor, @endColor);
    background: -o-linear-gradient(top, @startColor, @endColor);
}
.horizontal-gradient (@startColor: #eee, @endColor: white) {
    background-color: @startColor;
    background-image: -webkit-gradient(linear, left top, right top, from(@startColor), to(@endColor));
    background-image: -webkit-linear-gradient(left, @startColor, @endColor);
    background-image: -moz-linear-gradient(left, @startColor, @endColor);
    background-image: -ms-linear-gradient(left, @startColor, @endColor);
    background-image: -o-linear-gradient(left, @startColor, @endColor);
}

.animation (@name, @duration: 300ms, @delay: 0, @ease: ease) {
    animation:                  @name @duration @delay @ease;
}

.transition (@transition) when (@app-default-transitions = 1) {
    transition:                 @transition;  
}
.transition (@transition) when (@app-default-transitions = 0) {}

.transform(@string){
    transform:                  @string;
}
.scale (@factor) {
    transform:                  scale(@factor);
}
.rotate (@deg) {
    transform:                  rotate(@deg);
}
.skew (@deg, @deg2) {
    transform: 		skew(@deg, @deg2);
}
.translate (@x, @y:0) {
    transform: 		translate(@x, @y);
}
.translate3d (@x, @y: 0, @z: 0) {
    -webkit-transform:          translate3d(@x, @y, @z);
    -moz-transform:             translate3d(@x, @y, @z);
    -ms-transform: 		translate3d(@x, @y, @z);
    -o-transform: 		translate3d(@x, @y, @z);
}
.perspective (@value: 1000) {
    -webkit-perspective: 	@value;
    -moz-perspective:           @value;
    -ms-perspective:            @value;
    perspective: 		@value;
}
.transform-origin (@x:center, @y:center) {
    -webkit-transform-origin:   @x @y;
    -moz-transform-origin:      @x @y;
    -ms-transform-origin:       @x @y;
    -o-transform-origin:        @x @y;
}

.noselect{
    -webkit-touch-callout:      none;
    -webkit-user-select:        none;    
    -moz-user-select:           none;
    -ms-user-select:            none;
    user-select:                none;
}