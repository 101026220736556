.make-btn(@name, @background, @color, @border: darken(@background,3%)){
    &.@{name}{
        border-color: @border;
        background: @background;
        color: @color;

        &:hover,&.active,&:active, &:active:focus{
            border-color: darken(@border, 5%);
            background: darken(@background, 3%);
            color: darken(@color,5%);                        
        }
        
        &:focus{
            border-color: darken(@border, 5%);
            background: darken(@background, 3%);
            color: @color;
        }

        &.btn-clean{
            background: transparent;
            color: @background;
            border:solid 2px @background;
            padding:8px 28px;
            box-shadow:none;
            
            & when (@name = btn-default) {

                color: @ink;
                border-color:@smoke;

            }

            & when (@name = btn-danger) {

            }
            
            &:hover,&:active,&:active:focus{
                color: @color;
                background: @background;
                border-color: @background;                
                
                & when (@name = btn-default) {
                    border-color: darken(@border,10%);
                    color: darken(@ink,10%);
                }


            }


            &.btn-default{
                border: 1px solid @silver;
            }

            &.btn-sm{
                padding: 4px 15px;
            }
        }
        
        &.btn-rounded{
            border-radius:20px;
        }
        &.btn-icon-fixed{
            & when (@name = btn-inline-success), (@name = btn-inline-warning), (@name = btn-inline-info), (@name = btn-inline-primary), (@name = btn-inline-danger) {
                svg{
                    //fill:@color;
                }
            }

            //& when (@name = btn-default) {
            //    svg{
            //        color:@ink;
            //    }
            //}
        }
    }
}
.make-open-btn(@name, @background, @color, @border: darken(@background,3%)){
    border-color: darken(@border, 5%);
    background: darken(@background, 3%);
    color: @color;     
}
.btn{

    .make-btn(btn-default, @misty, @ink, @smoke);
    .make-btn(btn-primary, @ink, @white);
    .make-btn(btn-success, @green, @white);
    .make-btn(btn-info, @blue, @white);
    .make-btn(btn-warning, @orange, @white);
    .make-btn(btn-danger, @red, @white);
    .make-btn(btn-link, transparent, @pewter, transparent);

    .make-btn(btn-inline-primary, @smoke, @ink, @smoke);
    .make-btn(btn-inline-default, @ink, @smoke, @ink);
    .make-btn(btn-inline-success, @light-green, @green, @light-green);
    .make-btn(btn-inline-info, @light-blue, @blue, @light-blue);
    .make-btn(btn-inline-warning, @light-orange, @orange, @light-orange);
    .make-btn(btn-inline-danger, @light-red, @red, @light-red);

    &.btn-inline-default,
    &.btn-inline-primary,
    &.btn-inline-success,
    &.btn-inline-info,
    &.btn-inline-warning,
    &.btn-inline-danger{
        box-shadow:none;
        padding:10px 15px;
        font-size:13px;

        svg{
            left:0 !important;
            margin-right:10px;
        }
    }

    &.btn-inline-default{
        svg{
            //fill:@white;
        }
    }


    &.btn-icon-fixed{

        svg{
            position: relative;
            left: -12px;
            top: -1px;
            width: 24px;
            height: 24px;
            padding: 0;
            //fill: #fff;
            vertical-align: middle;
            float:none;
        }

        &.btn-sm{
        }
    }

    &.btn-sm{
        padding: 6px 15px;
        &.btn-icon{
            width: 36px;
            height: 36px;
            line-height: 36px;
            padding:0;

            &.btn-vote{
                line-height: 30px;
            }
        }

        svg{
            left:0;
            margin-right:10px;
        }
    }
    
    &.btn-icon{
        padding-left: 0;
        padding-right: 0;
        width: 58px;

        svg{
            margin:0;
            padding:0;
            width:18px;
            height:18px;
            vertical-align: middle;
        }

        &.btn-xs{
            padding:4px;
            width: auto;
            line-height: 16px;
            border-width:1px;

            svg{
                width:16px;
                height:16px;
            }
        }
    }

    &.dropdown-toggle{
        box-shadow:none;
    }

    &.remove_field svg{
        fill:#fff;
    }

    &.disabled, &[disabled]{
        opacity: .45
    }
}

.btn-default.bootstrap-touchspin-down,
.btn-default.bootstrap-touchspin-up{
    padding:9px 10px;
    box-shadow: none;
    border: solid 1px @silver;
}

fieldset[disabled] .btn{
    opacity: .45;
}

.open{
    > .dropdown-toggle{        
        .make-btn(btn-default, #FFF, @ink, @smoke);
        .make-btn(btn-primary, @cloudy, @white);
        .make-btn(btn-success, @green, @white);
        .make-btn(btn-info, @blue, @white);
        .make-btn(btn-warning, @orange, @white);
        .make-btn(btn-danger, @red, @white);
        .make-btn(btn-link, transparent, @pewter, transparent);
    }    
}

.btn-group{
    &.open{
        .dropdown-toggle{
            .box-shadow(inset 0px 1px 2px rgba(0,0,0,0.1));
            &.btn-primary:focus, &.btn-primary:hover{
                background-color: @cloudy;
                color:red;
            }
        }
    } 
    
    &.btn-group-justified{
        .btn-group:nth-child(n+2){
            .btn{margin-left: -1px;}
        }
        .btn-group:nth-child(n+3){
            .btn{margin-left: -2px;}
        }
        .btn-group:nth-child(n+4){
            .btn{margin-left: -3px;}
        }
        .btn-group:nth-child(n+5){
            .btn{margin-left: -4px;}
        }
    }
    
    > .btn + .dropdown-toggle{
        padding-left: 15px;
        padding-right: 15px;
    }
}
.btn-group > .btn:hover, 
.btn-group-vertical > .btn:hover, 
.btn-group > .btn:focus, 
.btn-group-vertical > .btn:focus, 
.btn-group > .btn:active, 
.btn-group-vertical > .btn:active, 
.btn-group > .btn.active, 
.btn-group-vertical > .btn.active{
    z-index: 0;
}

.input-group-btn{
    > .btn{
        &:hover, &:focus, &:active{
            z-index: 0;
        }
    }

    &:first-child{
        > .btn,
        > .btn-group{
            margin-right: -2px;
        }
    } 
}

@media (max-width: @screen-md) {
    .btn{
        margin-bottom: 5px;
    }
}

#table-action-buttons{display:none}
.permissions-required{
    .btn{display:none}

    &.details-allowed .btn-details,
    &.payment-allowed .btn-payment,
    &.edit-allowed .btn-edit,
    &.delete-allowed .btn-delete,
    &.print-allowed .btn-print,
    &.return-allowed .btn-return,
    &.btn.btn-delete-group,
    &.reactivate-allowed .btn-resend{
        display:inline-block;
    }

    &.purchaseorder-allowed .btn,
    &.subrental-allowed .btn{
        display:block;
    }
}
.edit-allowed .btn-make-primary {
    display: inline-block !important;
}

.dropdown-menu .add-po, .dropdown-menu .add-subrental{
    display:none;
}

.purchaseorder-allowed .dropdown-menu .add-po,
.subrental-allowed .dropdown-menu .add-subrental{
    display:block;
}
.btn.btn-default {box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.08)}
.heading-line-below button{position: relative;top: -5px; right: -5px;}