    @switch-default: @white;
    @switch-success: @green;
    @switch-danger: @red;
    @switch-border: @smoke;
   
    /* switch button */
    .switch{
        cursor:pointer;
        position:relative; 
        height: 30px;
        line-height: 30px;
        margin: 5px 0;
        
        input{
            position:absolute;
            opacity:0;
            filter:alpha(opacity=0);
            
            &:checked+span:after{left: 16px;}
            &:checked+span:before{
                content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="#00c363" viewbox="0 0 14 14" stroke="none"><path d="M13.055 4.422q0 0.312-0.219 0.531l-6.719 6.719q-0.219 0.219-0.531 0.219t-0.531-0.219l-3.891-3.891q-0.219-0.219-0.219-0.531t0.219-0.531l1.062-1.062q0.219-0.219 0.531-0.219t0.531 0.219l2.297 2.305 5.125-5.133q0.219-0.219 0.531-0.219t0.531 0.219l1.062 1.062q0.219 0.219 0.219 0.531z"/></svg>');
                top: 2px;
                right: 2px;
                left: initial;
            }

            &:checked+span{background-color: @switch-success;}
            &:disabled+span{
                background-color: @switch-default;
                
                &:after{
                    .box-shadow(none);
                    border: 1px solid @switch-border;
                }
            }

            &+span:before {
                content:"\2716";
                width: 14px;
                height: 14px;
                position: absolute;
                z-index: 2;
                top: 0;
                left: 4px;
                color: #fff;
                font-size:13px;
            }

            &:indeterminate + span:before{
                content:"*";
                font-size: 25px;
                color: @orange;
                top: 5px;
            }
        }            
        
        span{
            position:relative; 
            width: 45px;
            height: 30px;
            .border-radius(30px);
            background-color: @switch-danger; 
            border:1px solid rgba(0,0,0,0.1);
            display: inline-block; 
            .transition(all @app-default-transition-speed linear);             
            left: 0;
            
            &:after{
                content:""; 
                position:absolute; 
                background-color: @red;
                width: 26px;
                top:1px; 
                bottom:1px; 
                left:1px; 
                .border-radius(25px);
                .box-shadow(1px 1px 2px rgba(0,0,0,0.1)); 
                .transition(all @app-default-transition-speed linear); 
            }
        }
        
        &.switch-sm{
            height: 20px;
            line-height: 20px;
            margin: 10px 0;
            
            input{
                &:checked+span:after{left: 16px;}
            }
            
            span{
                width: 35px; 
                height: 20px; 
                .border-radius(10px);
                
                &:after{width: 16px;}
            }
        }

    }
    /* ./switch button */

    .inner-title+.switch.switch-sm{
        margin: 0;
        position: relative;
        top: 5px;
    }