    @app-cr-bg: @white;
    @app-cr-color: @ink;
    @app-cr-border: @smoke;
   
    .app-checkbox, .app-radio{
        line-height: 20px;
        margin: 10px 0;
        float: left;
        width: 100%;
        
        label{
            padding-left: 30px;
            font-weight: 400;
            position: relative;
            cursor: pointer;
            margin-bottom: 0;
            
            input{
                position: absolute;
                left: 0;
                top: 0;
                width: 20px;
                height: 20px;
                opacity: 0;
                z-index: 3;
                margin: 0;
                cursor: pointer;
                
                &:checked + span{
                    &:after{
                        .transform(scale(1, 1));
                        fill:#fff;
                    }
                }
                
                &:disabled + span{
                    opacity: 0.5;
                }
            }
            
            span{
                position: absolute;
                left: 0;
                top: 0;
                width: 20px;
                height: 20px;
                
                &:before{
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 1;
                    width: 20px;
                    height: 20px;
                    border: 1px solid @app-cr-border;
                    background: transparent;
                    content: " ";
                }

                &:after{
                    position: absolute;                    
                    z-index: 2;               
                    color: @app-cr-color;
                    .transform(scale(0, 0));
                    .transition(transform @app-default-transition-speed cubic-bezier(0.8, -0.33, 0.2, 1.33));
                }
            }
        }
        
        &.inline{
            display: inline-block;
            margin-right: 20px;
            float: none;
            width: auto;
            
            &:last-child{
                margin-right: 0;
            }
        }
    }
    
    .app-checkbox{

        .heading-line-below{
            color: #a4a6b0;

            label{
                font-size: 18px;
                line-height: 26px;
            }
        }

        input[type="checkbox"] + span::after {
            content:"";
        }

        input[type="checkbox"]:checked + span:after {
            display: inline-block;
            transform: rotate(45deg);
            height: 12px;
            width: 6px;
            border-bottom: solid 2px #fff;
            border-right: solid 2px #fff;
            content: "";
            position: absolute;
            left: 7px;
            top: 3px;
        }

        input[type="checkbox"]:checked + span:before{
            background: @blue !important;
            border:none;
        }

        label{            
            span{
                &:after{
                    font-size: 14px;
                    text-align: center;                    
                    line-height: 20px;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    display:block;
                    top:3px;
                }
                &:before {
                    border-radius:@app-design-border-radius;
                    border-color:@silver;
                }
            }
        }
        
        &.success{
            label{
                color: @green;
                
                span{
                    &:before{border-color: @green;}
                    &:after{color: @green;}
                }
            }             
        }
        &.warning{
            label{
                color: @orange;
                
                span{
                    &:before{border-color: @orange;}
                    &:after{color: @orange;}
                }
            }   
        }
        &.danger{
            label{
                color: @red;
                
                span{
                    &:before{border-color: @red;}
                    &:after{color: @red;}
                }
            }   
        }
    }
    
    .app-radio{
        label{                        
            span{                
                &:after{                 
                    content: " ";
                    background: @blue;
                    width: 12px;
                    height: 12px;
                    left: 4px;
                    top: 4px;
                    border-radius: 10px;
                }
                &:before {
                    border-color:@silver;
                    border-radius: 10px;
                }
            }
        }
        
        &.round{
            label{
                span{
                    &:before,&:after{
                        .border-radius(50%);
                    }
                }
            }
        }
        
        &.success{
            label{
                color: @green;
                
                span{
                    &:before{border-color: @green;}
                    &:after{background: @green;}
                }
            }            
        }

        &.danger{
            label{
                color: @red;
                
                span{
                    &:before{border-color: @red;}
                    &:after{background: @red;}
                }
            }
        }
    }
    
    .input-group{
        .input-group-addon{
            .app-checkbox, .app-radio{
                margin: 0;
                
                label{
                    float: left;
                    padding-left: 20px;
                    margin-top: -10px;
                }
            }
        }
    }
    
    .table{
        .app-checkbox,.app-radio{
            margin: 0;
            width: 20px;
        }
    }

    // Radio Images

    .radio-image-wrapper{
        background-color: @cloudy;
        border: solid 3px @white;
        padding: 12px;
        height: 100%;
        display:flex;
        align-items:center;
        transition: all 0.3s ease-in-out 0s;
        cursor: pointer;
        border-radius: @app-design-border-radius;
        position: relative;
    }

    .radio-img{
        width:150px;
        height:60px;
        display: block;
        float:left;
        margin-right:15px;
        border: solid 1px @cloudy;
        border-radius: @app-design-border-radius;
        background: @white;

        > input {
            display:none;

            &:checked + .radio-image-wrapper{
                border: solid 3px @blue;

                &:after {
                    width: 18px;
                    height: 18px;
                    position: absolute;
                    right: -8px;
                    top: -8px;
                    padding: 1px;
                    background: @blue;
                    border-radius: 50%;
                    line-height: 18px;
                    text-align: center;
                    content: "\2714";
                    color: @white;
                    font-size: 10px;
                }
            }
        }

        img{
            cursor:pointer;
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
        }

        span:not(.radio-image-wrapper) {display: none}
    }


    // Dual choice toggle

    .rec-checkbox {
        border-radius: @app-design-border-radius;
        border: solid 1px @silver;
        margin-right: 5px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
            display: none;

            &:not(:checked) + span span:first-child,  &:checked + span span:last-child {
                background-color: @blue;
                color: @white;
            }
        }

        span {
            font-size: 0;

            span {
                background-color: @white;
                color: @ink;
                display: inline-block;
                padding: 9px 16px;
                border-radius: @app-design-border-radius;
                font-size: 14px;
                transition: background-color .15s;

                &:first-child {
                    border-right: solid 1px @silver;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

                &:last-child {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }
