.app {
  .list-group {
    &.list-group-noborder .list-group-item {
      border: 0;
    }
    .list-group-title {
      font-weight: 700;
      font-size: 11px;
      text-transform: uppercase;
      line-height: 20px;
      margin-top: 15px;
      margin-bottom: 5px;
      color: @ink;
      padding: 5px 15px;
    }
    .list-group-noborder{
      .list-group-item.active,
      .list-group-item.active:focus,
      .list-group-item.active:hover{
        background: @misty;
        color: @ink;
      }
    }

    .list-group-item .icon {
      margin-right:10px;
      width:20px;
      height:20px
    }
  }

}

.user-profile-list .list-group-item {
  border: none;
}








