@app-widget-tile-bg: #FFF;
@app-widget-tile-color: @ink;
@app-widget-tile-border: @smoke;

@app-widget-button-bg: #FFF;
@app-widget-button-bg-hover: #F5F5F5;
@app-widget-button-border: @smoke;
@app-widget-button-color: @ink;


.app {
    // widget tile
    .app-widget-tile-build-style(@name,@bg,@color) {
        &.@{name} {
            background: @bg;
            border: 1px solid darken(@bg, 1%);

            .title, .intval, .icon {
                color: @color;
            }
            .subtitle {
                color: darken(@color, 10%);
            }
        }
    }

    .app-widget-tile {
        float: left;
        width: 100%;
        padding: 10px 15px;
        min-width: 90px;
        background: @app-widget-tile-bg;
        border: 1px solid @app-widget-tile-border;
        margin-bottom: 20px;
        .border-radius(3px);
        .clearfix();

        .row {
            margin: 0px;

            [class^='col-'] {
                padding: 0px;
            }
        }

        .line {
            float: left;
            width: 100%;
        }
        .title, .subtitle {
            color: @ink;
            font-size: @app-layout-base-font-size - 2;
            font-weight: 600;
            float: left;
            text-transform: uppercase;
        }
        .subtitle {
            color: @pewter;
        }

        .intval {
            font-size: 27px;
            line-height: 50px;
            font-weight: 600;
            text-align: center;
            float: left;
            width: 100%;

            small {
                font-size: 16px;
                color: @pewter;
                text-transform: uppercase;
                line-height: 20px;
            }

            &.intval-lg {
                line-height: 60px;
                font-size: 34px;
            }
        }

        .icon {
            float: left;
            width: 100%;
            text-align: center;
            padding-right: 10px;

            [class^='icon-'] {
                font-size: 40px;
                line-height: 70px;
            }

            &.icon-right {
                padding-right: 0px;
                padding-left: 10px;
            }

            &.icon-lg {
                [class^='icon-'] {
                    font-size: 60px;
                    line-height: 90px;
                }
            }
        }

        &.app-widget-highlight {
            .box-shadow(0px 2px 6px rgba(0, 0, 0, 0.03));
        }

        .app-widget-tile-build-style(app-widget-tile-primary, @cloudy, @white);
        .app-widget-tile-build-style(app-widget-tile-info, @blue, @white);
        .app-widget-tile-build-style(app-widget-tile-success, @green, @white);
        .app-widget-tile-build-style(app-widget-tile-warning, @orange, @white);
        .app-widget-tile-build-style(app-widget-tile-danger, @red, @white);
    }
    // end widget tile
}