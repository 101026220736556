        
label{
    font-weight: 600;
}

// input group
.input-group{
    .input-group-addon{
        min-width: 40px;
        border-color: @silver;
        background: @misty;
        color: @ink;
        font-weight: 600;

        svg{
            padding:4px;
        }

        + .form-control{
            border-left:none; border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important;

            &.number-rfloated{
                border-left: 1px solid @silver !important;
                border-right: none !important;
            }
        }
    }

    &readonly .input-group-addon{
        background: #f4f6f7;
        border:none;
    }

}
// end input group

// form input control
.form-control{
    height: 40px;
    border: solid 1px @silver;
    color: @ink;
    background: @white;

    &:focus{
        outline: none;
        border-color: @ink;
        .box-shadow(none);
    }

    &[multiple]{
        padding: 5px 0px;

        option{
            padding: 3px 5px;
            margin-bottom: 3px;
        }
    }

    &[readonly]{
        color: @pewter;
    }
    &[disabled]{
        opacity: 0.5;
    }
    &[type="file"] {
        padding-top: 8px;
    }
}
input[type="file"]{
    margin-top: 8px;
}
// end form input control

// help block
.help-block{
    color: @pewter;
    margin-bottom: 0px;

    &.control-label{
        margin-top: 0px;
        font-weight: 400;
    }
}
// end help block

// Custom File Input
.file-input{
    position: relative;
    cursor: pointer;
    overflow: hidden;

    input[type=file], input[type=file]:focus, input[type=file]:hover {
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        z-index: 2;
        outline: 0;
        width: 100%;
        height: 100%;
        .opacity(0);
    }
}
.file-input-name{margin-left: 10px;}
// end custom file input

// horizontal form
.form-horizontal{

    .control-label{
        text-align: left;
        font-weight: 600;
        padding-top: 10px;
        margin-bottom:5px;
        font-family:@app-layout-titles-font-family;
        &.help-block{
            font-weight: 400;
            font-family:@app-layout-base-font-family;
        }
    }

    .form-group{
        margin:0 0 20px 0px;
    }

    &.checkbox, &.checkbox-inline, &.radio, &.radio-inline{padding-top:0}
}
// end horizontal form

// default radio and checkbox
.radio, .checkbox{
    float: left;
    width: 100%;
    line-height: 20px;

    &:first-child{
        margin-top: -10px;

        &:last-child{
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }

    display: block;
    min-height: 0;
    padding-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.radio + .radio, .checkbox + .checkbox{
    margin-top: 0px;
}
.radio input[type="radio"] {
    margin-left: -40px !important;
    margin-top: 1px;
}
// end default radio and checkbox

// form group
.form-group,.input-group{
    margin-bottom: 15px;
    float: left;
    width: 100%;
    position: relative;
}

.form-group{
    .input-group{
        margin-bottom: 0px;
    }

    > label + .help-block{
        margin-top: -5px;
        margin-bottom: 10px;
    }

    &:last-child{
        margin-bottom:20px;
    }
}
// end form group

// control sizing
.input-lg{
    height: 50px;
    font-size: @app-layout-base-font-size + 3;
}
.input-sm{
    height: 30px;
    font-size: @app-layout-base-font-size - 1;
}
// end control sizing

// form validation states

.validation_state_build(@background){
    .form-control:focus{
        .box-shadow(none);
    }
    .input-group-addon{
        border-color: @background;
        color: @background;
    }
    .form-control{
        border: solid 1px @background;
        color: @background;

        &:focus{
            border-color: darken(@background,5%);
        }
    }
    label, .control-label, .help-block{
        color: @background;
    }

    &.has-feedback{
        .form-control-feedback{
            color: @background;
        }
    }
    .app-checkbox{
        span{
            &:before{border-color: @background;}
            &:after{color: @background;}
        }
    }

    .app-radio{
        span{
            &:before{border-color: @background;}
            &:after{background: @background;}
        }
    }
}

.has-feedback label ~ .form-control-feedback{
    top: 25px;
}

.form-control-feedback{
    width: 40px;
    height: 40px;
    line-height: 40px;

    *[class^="icon-"]{
        font-size: 20px;
    }
}

//.has-success{
//    .validation_state_build(@green);
//}
.has-warning{
    .validation_state_build(@orange);
}
.has-error{
    .validation_state_build(@red);
}
// end form validation states


/**** Selectize *****/
.selectize-control.plugin-remove_button [data-value] .remove{
    color:@red !important;
}
.selectize-dropdown [data-selectable]{
    padding: 3px 12px;
}

.input-group .selectized.form-control {
    position: initial;
}

.input-group .selectize-dropdown.form-control{
    position: absolute;
}
.selectize-input {
    border-color: @silver !important;
    min-height:40px !important;
}
.selectize-input.focus{
    box-shadow: none !important;
}

.has-error .selectize-input{
    border-color: @red !important;
}
.input-group .form-control.selectize-control{z-index:initial}
textarea.form-control{border-radius:@app-design-border-radius !important;}
/*****************************************/


.form-control::-moz-placeholder {
    color: @silver;
    opacity: 1;
}
.form-control:-ms-input-placeholder {
    color: @silver;
}
.form-control::-webkit-input-placeholder {
    color: @silver;
}

fieldset > legend {margin-top:40px}
fieldset:first-child > legend {margin-top:0}

.form-heading {
    margin-top:30px;
    color:#a4a6b0;

    svg{
        //fill:@silver;
    }

    icon {
        width:20px;
        height: 20px;
        margin-right:10px;
        margin-top:-1px
    }
}

label + .text-danger{
    font-size:20px;
}

.checkbox-list > label {
    display: block;
}

div.radio, div.checker {
    margin-right: 0;
    margin-left: 3px;
}

div.selector,
div.checker,
div.button,
div.uploader {
    display: -moz-inline-box;
    display: inline-block;
    zoom: 1;
    vertical-align: middle;
}