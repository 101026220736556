@charset "UTF-8";

// import Fonts

// settings and vars

@import "variables.less";


// import components
@import "helpers/mixins.less";
@import "helpers/classes.less";

@import "components/app-layout.less";
@import "components/app-header.less";

@import "components/app-navigation.less";

@import "components/app-typography.less";
@import "components/app-form-elements.less";

@import "components/app-checkbox-radio.less";
@import "components/app-switch-button.less";

@import "components/app-buttons.less";
@import "components/app-icons.less";

@import "components/app-elements.less";
@import "components/app-blocks-panels.less";
@import "components/app-alerts.less";
@import "components/app-tabs.less";
@import "components/app-popups.less";
@import "components/app-labels-badges.less";
@import "components/app-tiles.less";
@import "components/app-features.less";

@import "components/app-login.less";
@import "components/app-tables.less";
@import "components/app-payment.less";
@import "components/app-progress-bars.less";
@import "components/app-spinner.less";
@import "components/app-contact.less";
@import "components/app-list.less";

@import "components/app-widgets.less";
@import "components/app-wizard.less";

@import "app-modules/global.less";
@import "app-modules/dashboard.less";
@import "app-modules/file-manager.less";
@import "app-modules/menu.less";
@import "app-modules/notifications.less";
@import "app-modules/orders.less";
@import "app-modules/products.less";

@import "overrides.less";