@font-face {
    font-family: 'Proxima Nova';
    src: url('../../fonts/proximanova-bold-webfont.eot');
    src: url('../../fonts/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/proximanova-bold-webfont.woff') format('woff'),
    url('../../fonts/proximanova-bold-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../../fonts/proximanova-regular-webfont.eot');
    src: url('../../fonts/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/proximanova-regular-webfont.woff') format('woff'),
    url('../../fonts/proximanova-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../../fonts/proximanova-light-webfont.eot');
    src: url('../../fonts/proximanova-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/proximanova-light-webfont.woff') format('woff'),
    url('../../fonts/proximanova-light-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('../../fonts/proximanova-semibold-webfont.eot');
    src: url('../../fonts/proximanova-semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/proximanova-semibold-webfont.woff') format('woff'),
    url('../../fonts/proximanova-semibold-webfont.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


h1,h2,h3,h4,h5,h6{
    font-family: 'Proxima Nova', Helvetica, 'Helvetica Neue', Arial, sans-serif;
    padding: 0;
    margin: 0;
    font-weight: 700;
    text-shadow: none;
    
    svg{
        margin-right: 10px;
        font-size: 14px;
    }
}

h1{
    font-size: 22px;
    line-height: 35px;
    margin-bottom: 15px;

    svg{
        line-height: 32px;
    }
}
h2{
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 15px;

    svg{
        line-height: 28px;
    }
}
h3{
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 10px;

    svg{
        line-height: 24px;
    }
}
h4{
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;

    svg{
        line-height: 20px;
    }
}
h5{
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
    font-weight: 600;

    svg{
        line-height: 20px;
    }
}
h6{
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 5px;
    font-weight: 600;

    svg{
        line-height: 20px;
    }
}

p{
    margin-top: 5px;
    margin-bottom: 10px;
    
    &.subheader{
        margin-top: -5px;
        color: @pewter;
    }
    
    &:last-child{
        margin-bottom: 0;
    }
}

blockquote{
    padding: 5px 15px;
    margin-top: 10px;
    border-color: @smoke;
    font-size: @app-layout-base-font-size + 2;
    
    p{
        margin: 0 0 10px;
    }
    
    &.blockquote-reverse, &.blockquote.pull-right{
        border-color: @smoke;
    }    
}

ul,ol{
    padding-left: 20px;
    
    li{
        float: left;
        width: 100%;        
    }
    
    &.list-inline{
        width: auto;
        margin-left: 0;
        padding-left: 0;
        
        li{
            width: auto;
        }
    }
}

dl{
    margin-top: 10px;
    
    dt{
        font-weight: 600;
        margin-bottom: 5px;
    }
    dd{
        margin-bottom: 10px;
    }
}

.heading-line-below{
    position: relative;
    margin-bottom: 15px;
    
    &:before{
        content: " ";
        left: 0;
        bottom: -5px;
        height: 1px;
        width: 100%;
        position: absolute;
        background: @smoke;
    }

    &.thick-line:before{
        height: 2px;
        background: @ink;
    }
    
    &.heading-line-below-short{
        &:before{
            width: 20px;
            background: @white;
        }
    }
    
    &.text-center{
        &.heading-line-below-short{
            &:before{
                left: 50%;
                margin-left: -10px;
            }
        }
    }
}
.heading-line-middle{    
    position: relative;    
    
    &:before{
        content: " ";
        left: 0;
        top: 50%;
        height: 1px;
        width: 100%;
        z-index: 1;
        position: absolute;
        background: @smoke;
    }
    
    span{        
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        display: block;        
        background: @white;
        padding-right: 10px;
    }
         
}

.typography{
    p{
        line-height: 24px;
        margin-bottom: 20px;
    }
    ul{
        > li{
            line-height: 24px;
        }
    }
}