.app{

    .feather {
        display: inline-block;
        vertical-align: middle;
    }

    .app-sidebar-left-minimized .left-menu-nav .feather {
        margin:0;
    }

    .left-menu-nav .feather {
        margin-right:14px;
        margin-left:1px;
        padding:2px;
        width:24px;
        height:24px;
        //filter:grayscale(1) brightness(0.95);
    }
    .left-menu-nav a:hover .feather {
        filter:none;
    }

    .icon {
        display: inline-block;
        width: 25px;
        height: 25px;
        vertical-align: middle;
    }
    .left-menu-nav .icon {
        margin-right:14px;
        margin-left:1px;
        padding:2px;
    }

    // content tabs 
    .app-content-tabs{
        float: left;
        width: 100%;
        padding: 10px 15px 0px;
        //background: @white;
        border-bottom: 1px solid @smoke;
        
        > ul{
            float: left;
            width: auto;
            list-style: none;
            padding: 0px;
            margin-bottom: -1px;
            
            > li{
                width: auto;

                &.active > a {
                    color: @ink;
                    background: @misty;
                }
                
                > a{
                    display: block;
                    float: left;
                    line-height: 30px;
                    padding: 5px 20px;
                    margin-right: 5px;
                    border: 1px solid @smoke;
                    color: @pewter;
                    //.border-radiuses(@app-design-border-radius,@app-design-border-radius,0px,0px);
                    font-weight: 600;
                    text-decoration: none;                    
                    background: @white;
                    .transition(all @app-default-transition-speed linear);
                    
                    > span{
                        float: left;
                        line-height: 30px;
                        margin-right: 10px;
                        width: 20px;
                        text-align: center;
                        
                        &.close-tab{
                            margin-right: -10px;
                            margin-left: 10px;
                            float: right;
                            vertical-align: baseline;
                            line-height: 24px;
                            margin-top: 4px;
                        }
                    }
                    
                    span[class^="icon-"]{
                        font-size: 20px;
                    }
                    
                    span[class^="fa-"]{
                        font-size: 14px;
                    }
                                        
                    
                    &.app-content-tabs-new{
                        border: 0px;
                        
                        &:hover{
                            background: @white;
                        }
                    }
                    
                    &:hover{
                        color: @ink;
                        background: @misty;
                    }
                    
                    &.active{
                        color: @ink;
                        background: @cloudy;
                        border-bottom-color: @cloudy;
                    }                                                            
                }
                
                &.icon-only{
                    > a{
                        padding: 3px 8px;                        
                        
                        > span{
                            margin: 0px;
                        }
                    }
                }
            }
        }
    }
    
    .app-content-tab{
        display: none;
        
        &.active{
            display: block;
        }
    }
    // end content tabs
    
    
    // heading block         
    .app-heading{
        float: left;
        width: 100%;
        padding: 15px;        
        background: #fff;

        > .contact{
            display: inline-block;

            img {
                max-width: 70px;
            }
        }

        > .button{
            float: left;
            margin-right: 20px;            
        }
        
        .icon{
            float: left;
            margin-right: 10px;                    
            width: 20px;
            text-align: center;
            color: #F37821;

            span{
                line-height: 40px;
                font-size: 20px;
            }

            &.icon-lg{                        
                width: 30px;
                margin-right: 15px;
                
                span{
                    font-size: 30px;
                }
            }
        }

        svg.icon.icon-lg{
            width: 30px;
            height:30px;
            margin-right: 15px;
            fill:@orange;
        }

        .title{
            display: inline-block;
            position: relative;
            top: 6px;
            margin-left: 10px;

            h1,h2,h3,h4,h5,h6{
                margin: 0px;
                padding: 0px;
                float: left;
                font-size: 18px;
                line-height: 20px;
                font-weight: 600;
                clear: both;
                //width: 100%;
                margin-bottom: 5px;
                color: @ink;

                small{
                    display: inline-block;
                    border-left: 1px solid @smoke;
                    padding-left: 10px;
                    margin-left: 10px;
                }
            }

            p{
                float: left;
                //width: 100%;
                clear: both;
                margin-bottom: 0px;
                font-weight: 400;
                line-height: 15px;
                margin-top: 0px;
                color: @pewter;
            }                                        
        }

        .heading-elements{
            float: right;
            position: relative;
        }
        
        &.title-only{
            padding: 10px 15px;

            .title{
                h1,h2,h3,h4,h5,h6{
                    line-height: 40px;
                    margin-bottom: 0px;
                }
            }            
        }

        &.heading-transparent{
            margin-top: 10px;
            background: transparent;
            border: 0px;
        }
        
        &.app-heading-background{
            padding: 55px 15px;
            margin-top: 0px;
            border: 0px;
            color: #FFF;            
        }

        &.app-heading-small{
            > .title{
                h1,h2,h3,h4,h5,h6{
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
        
        &.app-heading-condensed{
            padding: 15px 0px;                                    
            
            &.app-heading-small{
                padding-top: 0px;
            }
            
            &:first-child{
                margin-top: 0px;
            }            
        }
        
        &.app-heading-condensed-horizontal{
            padding: 15px 0px;
        }
        
        &.app-heading-lead{
            padding-top: 25px;
        }
        
        &.app-heading-bordered{
            border: 1px solid @smoke;
            
            &.app-heading-page{
                border-top: 0px;
            }
        }
        
        &.app-heading-center{
            text-align: center;
            
            > .title{
                width: 100%;
            }
        }
        
        .form-group,.input-group{
            margin-bottom: 0px;
        }
        
        .container{
            &.container-boxed{
                margin: 0px auto;
            }
        }
        
        > .contact{
            width: auto;
        }
        &.app-heading-condensed{
            padding: 10px 15px;
        }
    }
    
    .app-heading-container{
        float: left;
        width: 100%;
        line-height: 40px;            
        //background: @misty;
        padding: 0px 15px;
        box-shadow:0 1px 4px 0 rgba(0,0,0,.1);
        background-color: #f6f8fa;
        
        &.app-heading-bordered{
            border: 1px solid @smoke;
            
            &.top{
                border-bottom: 0px;
            }
            &.bottom{
                border-top: 0px;
            }
        }

        &.bottom {

        }
        
        .container{
            &.container-boxed{
                margin: 0px auto;
            }
        }
    }
        
    .app-content{
        > .app-heading, > .app-heading-container{
            &.app-heading-bordered{
                border-left: 0px;
                border-right: 0px;
            }
        }
    }
    
    .app-sidepanel{
        .app-heading{
            background: transparent;
        }
    }
    // end heading block

    // universal breadcrumb
    .breadcrumb{
        float: left;
        background: transparent;
        border-radius: 0px;
        margin-bottom: 0px;
        padding: 12px 0px 13px;
        width: auto;

        > li{
            line-height: 15px;
            float: left;
            font-size: 12px;
            width: auto;

            > a{
                float: left;
                line-height: 15px;
                color: @pewter;
                text-decoration: none;
                .transition(color @app-default-transition-speed linear);

                &:hover{
                    color: darken(@pewter,10%);
                }
            }

            &.active{
                color: @ink;
            }
        }

        > li + li:before{
            line-height: 14px;
            padding: 0px 5px;
            content: "\279E";
            font-size: 12px;
            float: left;
            width: 20px;
            text-align: center;
            color: @pewter;
            margin-top: 1px;
        }

        &.breadcrumb-condensed{
            padding: 0px;
        }
    }
    .app-header{
        .breadcrumb{
            margin-right: 10px;
        }
    }
    // end universal breadcrumb

    // pagintaion
    .pagination{
        width: auto;
        margin: 16px 14px 16px 0 !important;
        
        li{
            width: auto;
            
            > a, > span{
                padding: 5px;
                line-height: 20px;
                border-radius:@app-design-border-radius;
                border:none;
                min-width: 34px;
                text-align: center;
                .transition(all @app-default-transition-speed linear);
                margin-bottom: 3px;
            }
            
            > a{
                &:hover{
                    box-shadow: @block-box-shadow;
                    border-color: @smoke;
                    background: @ink;
                    color: #fff;
                }
            }
            
            &.active{
                > a, span{
                    box-shadow: @block-box-shadow;
                    border-color: @smoke;
                    background: @ink;
                    color: #fff;
                }
            }
            &.disabled{
                > a, span{
                    .opacity(0.7);               
                }
                 > a{
                    &:hover{
                        background: @white;
                        box-shadow:none;
                        color:#888;
                    }
                 }
            }                        
        }
        
        &.pagination-separated{
            li{
                margin-right: 5px;
                
                &:last-child{
                    margin-right: 0px;
                }
            }
        }
        
        &.pagination-rounded{
            li{
                margin-right: 5px;
                
                > a, > span{
                    .border-radius(@app-design-border-radius);
                }
            }
        }
        
        &.pagination-noborder{
            li{
                margin-right: 5px;
                
                > a, > span{
                    border: 0px;
                    .border-radius(@app-design-border-radius);
                }
            }
        }
        
        &.pagination-sm{
            li{
                > a, > span{                    
                    padding: 0px 10px;
                    min-width: 20px;
                    font-size: @app-layout-base-font-size - 1;
                }
            }
        }
        &.pagination-lg{
            li{
                > a, > span{                    
                    padding: 20px 10px;
                    min-width: 60px;                    
                }
            }
        }
    }
    // end pagintaion

    table {
        .product-name {font-family: @app-layout-titles-font-family; font-weight:500}
        .product-barcode {color:#A0A5AE}

    }
    .invent-q {display: block}
    strong {font-family: @app-layout-titles-font-family;}
    // pager 
    .pager{
        li{
            width: auto;
            float: none;
            
            > a{
                color: @ink;
                background: @white;
                .transition(all @app-default-transition-speed linear);
                .border-radius(@app-design-border-radius);
                line-height: 20px;
                padding: 10px 15px;
                
                &:hover{
                    background: @misty;
                }
            }
            
            &.disabled{
                > a{
                    .opacity(0.7);
                    
                    &:hover{
                        color: @ink;
                        background: @white;
                    }
                }
            }
        }
    }
    // end pager 
    
    // content container
    .container{
        float: left;
        width: 100%;
        margin: 15px 0px 15px;

        > .app-heading{
            margin-top: 15px;
        }
        
        &.container-boxed{
            width: @screen-lg;
            margin: 15px auto;
            float: none;
            .clearfix();
        }
    }
    // end content container
    
    // row table mode    
    .row{
        &.row-table{
            margin-left: 0px;
            margin-right: 0px;
            border-bottom: 1px solid @smoke;
            
            > div[class^="col-"]{                
                border-right: 1px solid @smoke;
                
                &:last-child{
                    border-right: 0px;
                }
            }                        
        }
    }
    .row-table-holder{
        padding: 0px !important;
        margin: 0px !important;
        
        .row.row-table{
            
            > div[class^="col-"]{
                padding: 15px 15px;
            }
            
            &:last-child{
                border-bottom: 0px;
            }
        }
    }
    // end row table mode
    
    .table-responsive{
        float: left; width: 100%;
    }
}

@media (max-width: @screen-md) {
    .app{
        .row{
            &.row-table{         
                border-bottom: 0px;
                
                > div[class^="col-"]{                
                    border-bottom: 1px solid @smoke;
                    border-right: 0px;                    
                    margin-bottom: 0px;
                }                        
            }
        }
    }
}

@media (max-width: @screen-xs) {
    .app{    
        .app-heading{
            .heading-elements{
                float: left;
                width: 100%;
                margin-top: 10px;
            }
            
            &.title-only{
                .heading-elements{ 
                    margin-top: 0px;
                }
            }
        }        
    }
}

@media (max-width: @screen-lg) {
    .app{
        .container{
            &.container-boxed{
                float: left;
                width: 100%;            
            }
        }
    }
}