.is-default-variant{
    display:inline-block;

    svg{
        border-radius: 50%;
        color: @smoke;
        background: #4f5467;
        padding: 3px;
        width: 18px;
        height: 18px;
    }

    &:hover svg{
        color:@pewter;
    }

    &.selected svg, &.selected:hover svg{
        color: @white;
        background: @green;
    }
}

.division-block:nth-child(2n){
    border-top:dashed 3px @silver;
    padding-top:15px;
}

.pro-img img {
    max-width:50px;
}
.category-icon-sample{
    font-size: 30px;
}

.thumbs-gallery:empty, .image-preview:empty{
    display: none;
}
