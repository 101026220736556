.returning-counter{
    margin-right:10px;

    .pr-count{
        width:34px;
    }

    &+span{
        margin-right:4px;
        top:6px;
        position: relative;
    }
}

#order-products .app-checkbox label input{
    position: relative;
}

.dashboard-orders{
    clear: both;
    margin-bottom: 18px;
}

.order-status-filter li, .payment-status-filter li{
    padding:0px 15px;
}

.table-totals{
    font-size: 1.2em;
    background: @ink;
    text-align: right;
    color:@white;
}

.order-info-panel.variants-info-panel li{white-space:normal}
.availability-page .timepicker td{min-width: initial}

.contract-preview{
    border:dotted 2px #e3e8f1;
    border-radius: 4px;
    padding:10px;

    &:empty{display: none}
}

div.tax-group {
    font-weight: bold;
}
div.group-taxes {
    padding-left: 20px;
}
div.group-taxes div.group-tax{
    font: 11px Arial;
    color: #606060;
}