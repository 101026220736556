@app-default-header-bg: transparent;
@app-default-header-color: @ink;
@app-default-header-height: 80px;
@app-default-header-height-affix: 50px;

@app-default-header-logo-background: darken(@app-navigation-element-background,3%);
@app-default-header-logo-background-light: #FFF;
@app-default-header-logo-width: @app-layout-sidebar-width;
@app-default-header-logo-condensed-width: 120px;

@app-header-navigation-color: @ink;
@app-header-navigation-color-hover: @orange;
@app-header-navigation-font-size: 12px;
@app-header-navigation-dropdown-bg: @white;
@app-header-navigation-dropdown-bg-hover: @misty;
@app-header-navigation-dropdown-font-size: 11px;
@app-header-navigation-dropdown-color: @ink;
@app-header-navigation-dropdown-color-hover: @orange;
@app-header-navigation-dropdown-border: @smoke;
@app-header-navigation-item-bg: @white;
@app-header-navigation-item-bg-hover: @misty;
@app-header-navigation-item-color: @white;
@app-header-navigation-item-color-hover: darken(@white,10%);

.app{        
    
    .app-header{
        float: left;
        width: 100%;        
        height: @app-default-header-height;
        background: @orange url('../../images/header-bg.png') no-repeat;
        position: relative;
        padding-top:12px;

        .app-header-logo{
            float: left;
            width: @app-default-header-logo-width;
            height: @app-default-header-height - 1;

            text-indent: 9999px;
            font-size: 0px;
            .transition(background @app-default-transition-speed linear);
            
            &.app-header-logo-condensed{                
                width: @app-default-header-logo-condensed-width;
                margin-right: 20px;
            }


        }

        .record-count{
            background-color: @ink;
            color: @white;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            text-align: center;
            display: block;
            line-height: 18px;
            font-size: 10px;
            font-weight: bold;
            position: absolute;
            top: -3px;
            right: 0;
        }
        
        .app-header-title{
            float: left;
            padding: 10px 10px 10px 15px;
            
            h1,h2,h3,h4,h5,h6,.title{
                font-size: 14px;
                line-height: 40px;
                font-weight: 600;
                padding: 0;
                margin: 0;
                                
                span{
                    font-size: 20px;
                    line-height: 40px;
                    float: left;
                    margin-right: 10px;
                }
            }
        }
        
        .app-header-buttons{
            float: left;
            padding: 9px 5px 10px 10px;
            margin: 0;
            list-style: none;
            width: auto;


            .icon-menu {
                color:@white
            }

            .btn-settings{
                background: none;
                border:none;
                svg{
                    margin-top: 6px;
                    position: relative;
                    fill:@white;
                }
            }

            .user-image {
                float:left;
                width:40px;
                margin-right:15px;
            }

            .user-info{
                float:left;
                color:@white;

                a{
                    color:@white;
                    font-weight: bold;
                    font-size: 15px;
                    display: block;
                }
            }

            > li{                
                float: left;
                position: relative;                
                margin-right: 15px;
                width: auto;
                
                > .contact{
                    margin-bottom: 0px;
                    
                    .contact-container{
                        margin-right: 10px;
                        >a, >span {color:#fff}
                    }
                }
                
                &:last-child{
                    margin-right: 0px;
                }
            }
            
            &.pull-right{
                padding-right: 15px;
            }
        }

        // header search
        .app-header-search{
           float: left;
           padding: 5px 15px;
           position: relative;
           
            > input{
                float: left;
                width: 40px;
                height:40px;
                padding: 0 0 0 40px;
                line-height: 38px;                
                overflow: hidden;
                .transition(width @app-default-transition-speed linear);
                border-radius:@app-design-border-radius;
                background: @ink;
                color:@white;
                border: none;
                
                &:focus{
                    width: 220px;                                        
                }

                &::placeholder {color:#eee}
            }
            
            &:after{
                position: absolute;
                left: 20px;
                top: 13px;
                font-size: 36px;
                content: "\0260C";
                text-align: center;
                cursor: pointer;
                transform: rotate(90deg);
                color:@silver;
            }            
        }
        // end header search
        
        
        .container{
            &.container-boxed{
                margin: 0px auto;
                
                .app-header-logo{
                    background-position-x: -15px;
                }
                
                .app-header-buttons{
                    &.pull-right{
                        padding-right: 0px;
                    }
                }
            }
        }
        
        .app-navigation-style-build(@name, @bg, @bg-logo, @bg-logo-light, @bg-logo-image, @border-color, @color, @color-secondary){
            &.@{name}{
                background: @bg;
                border-bottom: 1px solid @border-color;
                
                .app-header-logo{
                    background-color: @bg-logo;
                    background-image: @bg-logo-image;
                    &:hover{background-color: darken(@bg-logo,1%);}
                    
                    &.app-header-logo-light{
                        background-color: @bg-logo-light;                        
                        &:hover{background-color: darken(@bg-logo-light,1%);}
                    }
                }
                
                .app-header-title{
                    h1,h2,h3,h4,h5,h6,.title{                        
                        color: @color;
                    }
                }
                
                .app-header-buttons{
                    
                    .btn.btn-default, .btn.btn-link{
                        background: darken(@bg,4%);
                        color: @color;
                        border-color: darken(@bg,5%);
                        
                        span[class^="icon-"]{
                            color: @color;
                        }
                        
                        &:hover{
                            background: darken(@bg,5%);                                
                            border-color: darken(@bg,7%);
                        }
                    }
                    
                    > li{
                        > .contact.contact-bordered:after{
                            border-color: darken(@bg,5%);
                        }
                    }
                }
                
                .app-header-search{                    
                    padding: 9px 0px;
                    > input{
                        background: darken(@bg,4%);
                        color: @color;
                        border-color: darken(@bg,5%);
                        
                        &:focus{                            
                            border: 1px solid @border-color;
                            background: darken(@bg,1%);
                        }
                                                
                    }
                    
                    &:hover{
                        > input{
                            background: darken(@bg,2%);                                
                            border-color: darken(@bg,3%);
                        }
                    }
                    
                    &:after{                        
                        color: @color;
                    }            
                }
            }
        }
    }
}

@media (max-width: @screen-md) {
    .app{
        .app-container{
            &.app-header-inside-content{
                padding-top: @app-default-header-height;
                
                .app-content{
                    .app-header{
                        position: absolute;
                        left: 0px;
                        top: 0px;
                        z-index: 100;
                    }
                }
            }                                    
        }    
        
        .app-header{
            height: auto;
            
            .container{
                &.container-boxed{
                    padding-left: 0px;
                }
            }

            .app-header-search{
                > input{
                    &:focus{
                        width: 130px;
                    }
                }
            }
            .app-header-buttons{                
                .contact{
                    padding-left: 0px;
                    padding-right: 40px;
                    img,.contact-container{display: none;}
                    &:after{display: none;}
                }
            }
        }
    }
}