@app-navigation-background: transparent;
@app-navigation-element-color: #C5D1CD;
@app-navigation-element-color-hover: #FFF;
@app-navigation-element-title-color: #55698A;
@app-navigation-element-size: 11px;
@app-navigation-element-weight: 600;
@app-navigation-element-icon-color: #55698A;
@app-navigation-element-icon-color-active: #e69c0f;
@app-navigation-element-background: #2D3349;
@app-navigation-element-background-hover: darken(@app-navigation-element-background,2%);
@app-navigation-element-background-active: darken(@app-navigation-element-background,3%);
@app-navigation-element-border: darken(@app-navigation-element-background,2%);

@app-navigation-logo-background: darken(@app-navigation-element-background,3%);
@app-navigation-logo-height: 96px;


@app-navigation-horizontal-bg: @ink;
@app-navigation-horizontal-bg-active: #FFF;
@app-navigation-horizontal-border: #DBE0E4;
@app-navigation-horizontal-color: #fff;
@app-navigation-horizontal-color-hover: #FFF;
@app-navigation-horizontal-color-active: #1B2431;
@app-navigation-horizontal-secondary-color: lighten(#1B2431,10%);
@app-navigation-horizontal-secondary-bg-hover: #F5F5F5;
@app-navigation-horizontal-font-size: 14px;
@app-navigation-horizontal-font-weight: 500;
//@app-navigation-logo-height-minimized: 50px;

.app-navigation-style-build(@name, @nav-bg, @nav-logo-bg, @nav-logo-bg-hover, @nav-logo-bg-minimized, @nav-element-color, @nav-element-shadow, @nav-element-color-hover, @nav-element-size, @nav-element-weight, @nav-element-border, @nav-element-bg, @nav-element-bg-hover, @nav-element-bg-active, @nav-icon-color, @nav-icon-color-active,@nav-title-color){
    &.@{name}{
        background: @nav-bg;

        .app-navigation-logo{
            background: @nav-logo-bg;

            .app-navigation-logo-button{
                color: @white;
                height:55px;
                top:2px;
            }

            //&:hover{ background: @nav-logo-bg-hover; }
            & svg{width:25px; height:25px}
            & .st1{fill:@orange}
            & .st0{fill:@white;  stroke-dasharray: 20;}
        }

        nav{

            > ul > li.openable{
                > a:after{content: "\25BE"}

                &.open{
                    > a{background: @nav-element-bg-active;}
                }
            }

            > ul{
                > li{
                    border-bottom: 1px solid @nav-element-border;
                    transition: all .2s linear;
                    &:hover, &.active, &.open > a{background: #f8f8f8}

                    a{
                        background: @nav-element-bg;
                        color: @nav-element-color;
                        font-size: @nav-element-size;
                        font-weight: @nav-element-weight;
                        line-height: 40px;
                        border-left: solid 10px transparent;

                        span{
                            color: @nav-icon-color;
                        }

                        &:hover, &.active{
                            color: @nav-element-color-hover;
                            background: #f8f8f8;
                        }

                        &.active{
                            span{
                                color: @nav-icon-color-active;
                            }
                        }
                    }

                    ul{
                        > li{
                            > a{
                                padding-left:50px;
                                background: @cloudy;

                                &:hover,&.active{
                                    background: darken(@cloudy,5%);
                                }
                            }
                            &.open{
                                > a{
                                    background: darken(@cloudy,5%);
                                    border-left:solid 5px @orange;
                                }
                                > ul > li > a{
                                    color:@ink;
                                    &:hover,&.active{
                                        color: @orange;
                                    }
                                }

                            }
                        }
                    }
                    &.title{
                        font-size: @nav-element-size;
                        color: @nav-title-color;
                       // .text-shadow(@nav-element-shadow);
                    }
                }
            }
        }

        &.app-navigation-minimized{
            .app-navigation-logo{
                background: @ink;
                //height: @app-navigation-logo-height-minimized;
                //background-image: @nav-logo-bg-minimized;

                .app-navigation-logo-button{
                    display:block;
                }

            }

            &.ps{
                overflow:visible !important;
            }

            .menu-item-title {
                font-size: 12px;
                text-align: center;
                float: left;
                width: 100%;
            }

            nav{
                > ul > li > a > span{
                    color: @nav-element-color;
                    margin-left:0;
                }

                > ul > li:hover >ul{
                    box-shadow: 6px 2px 12px 1px rgba(193,205,227,0.50)
                }

                > ul > li ul{
                    z-index:99;
                }

                ul li.openable.open ul{
                    visibility:hidden;
                }

                ul li.openable.open:hover ul{
                    visibility:visible;
                }
            }

            img#logo{
                width: 40px;
                margin-left: 25px;
            }

            .left-menu-nav>ul{
                margin-top:-6px;
            }
            nav > ul > li a {
                border-left:none;
                line-height:22px;
                font-size:14px;
            }

            .left-menu-nav .icon {
                margin-right: 0;
            }
        }
    }
}
#logo{margin: 20px 4px 0 0;}
.menu-item-title {display:inline-block}

.app-navigation{
    background: @app-navigation-background;
    position: absolute;
    height: 100%;

    //build white navigation style
    .app-navigation-style-build(
        app-navigation-style-light,
        #FFF,
        transparent,
        transparent,
        transparent,
        @ink,
        @cloudy,
        #000,
        16px,
        500,
        #F2F2F2,
        #FFF,
        #fff,
        #F2F2F2,
        #000,
        #000,
        #AAA);

        nav ul ul>li.openable>a:after{
            padding:0;
        }
        nav ul ul>li.open>a{font-size:16px}

        &.app-navigation-style-light{

            nav>ul>li.openable.open>a,
            nav>ul>li ul>li>a{
                background: @cloudy;
            }

            nav ul>li.openable>a:after {
                content: "\25BE";
            }

            nav>ul>li ul>li>a.active,
            nav>ul>li ul>li>a:hover{
                color: @orange;
                background: @cloudy;
            }


            border-right: none;
            box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
            .app-navigation-logo{
                background:  url('../../images/logo-bg.png') -11px -49px no-repeat;

                position: relative;
                z-index:4;
            }
        }
    // end of build


    .app-navigation-logo{
        float: left;
        width: 100%;
        height: @app-navigation-logo-height;
        font-size: 0px;
        padding-left: 15px;
        position: relative;
        .transition(background-color @app-default-transition-speed linear);

        .app-navigation-logo-button {
            position: absolute;
            cursor: pointer;
            right: 0px;
            top: 2px;
            width: 55px;
            height: 95px;
            border: 0px;
            background: transparent;
            padding: 20px;
            .transition(background @app-default-transition-speed linear);

            .app-navigation-logo-button-alert{
                position: absolute;
                right: 5px;
                top: 5px;
                width: 16px;
                height: 16px;
                text-align: center;
                line-height: 16px;
                font-size: 10px;
                background: @red;
                color: @white;
                .box-shadow(0px 0px 0px 3px fade(@red,20%));

                .border-radius(2px);
            }

            span[class^='icon-']{
                font-size: 20px;
            }
        }
    }
    .left-menu-nav > ul {margin-top:-7px}
    nav{
        float: left;
        width: 100%;
        margin-top:0;
        > ul { margin: -20px 0 0 0px;}
        ul{
            display: block;
            float: left;
            width: 100%;
            padding: 0px;
            margin:0;
            list-style: none;

            > li{
                float: left;
                width: 100%;
                position: relative;

                > ul{
                    max-height: 0px;
                    overflow: hidden;
                }

                &.openable{
                    > a:after{
                        position: absolute;
                        top: 4px;
                        right: 0px;
                        line-height: 30px;
                        padding: 10px;
                        width: 40px;
                        text-align: center;
                        .transition(all @app-default-transition-speed linear);
                    }


                }

                &.open{
                    > a{
                        border-left: solid 10px @orange;
                        background: @cloudy;
                        &:after{
                            .rotate(180deg);
                        }
                    }
                    > ul{
                        max-height: 1000px;
                        overflow: visible;

                        a{
                            line-height: initial;
                            font-size:14px;
                            background: none;
                        }
                    }
                }
            }
        }

        > ul{
            > li{
                 a:first-child {background: transparent}
                a{
                    display: block;
                    float: left;
                    width: 100%;
                    line-height: 40px;
                    padding: 10px 30px 10px 5px;
                    text-decoration: none;
                    .transition(all @app-default-transition-speed linear);
                    position: relative;
                    white-space: nowrap;
                    background:none;

                    span{
                        text-align: center;
                    }

                    span.label{
                        position: absolute;
                        float: none;
                        width: auto;
                        right: 30px;
                        top: 16px;
                        margin: 0px;
                        &.label-ghost{
                            margin: 0px;
                        }
                    }

                }

                ul{
                    > li{
                        > a{
                            padding-left: 71px;
                        }

                        ul{
                            > li > a{
                                padding-left: 45px;
                            }
                        }
                    }
                }

                &.title{
                    line-height: 30px;
                    padding: 20px 0px 10px 15px;
                }
            }
        }
    }

    &.app-navigation-minimized{

        .app-navigation-logo{
            background-image: url('../../images/logo-sm.png');

            .app-navigation-logo-button{
                display: none;
            }
        }

        nav{

            ul{
                > li{
                    > a{
                        padding-left: 15px;
                    }
                }
            }

            > ul{
                .animation(nav-appear,300ms,0.4,linear);

                > li{
                    > a{
                        font-size: 0px;
                        padding: 10px;
                        text-align: center;

                        span{
                            width: 100%;
                            font-size: 20px;
                            margin-right: 0px;
                            margin-top: 0px;
                            line-height: 30px;

                            &.label{
                                display: none;
                            }
                        }
                    }

                    ul{
                        position: absolute;
                        left: 100%;
                        width: @app-layout-sidebar-dropdown-width;
                        z-index: 10;
                    }

                    &.openable{
                        > a:after{display: none;}
                    }
                    &.title{
                        display: none;
                    }
                }
            }

        }

        &.app-navigation-open-hover{
            nav{
                ul{
                    > li{
                        &.openable{
                            &:hover{
                                > ul{
                                    max-height: 800px;
                                    overflow: visible;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.app-navigation-fixed{
        float: none;
        position: fixed;
        left: 0px;
        top: 0px;
        z-index: 10 !important;

        &.app-navigation-fixed-absolute{
            position: absolute;
        }
    }
}

//.app.app-sidepanel-open{
//    .app-navigation.app-navigation-fixed{
//        left: -@app-layout-sidebar-width;
//    }
//}

@-webkit-keyframes nav-appear{
    0%   {opacity: 0;}
    75%  {opacity: 0;}
    100% {opacity: 1;}
}
@keyframes nav-appear{
    0%   {opacity: 0;}
    75%  {opacity: 0;}
    100% {opacity: 1;}
}

@app-navigation-mobile-bg: @app-navigation-background;
.app-navigation-mobile-bg{
    background: #2d3349;
}

@app-navigation-mobile-text-color: #EDEFF0;
@app-navigation-mobile-text-color-hover: #FFF;
@app-navigation-mobile-text-size: 13px;
@app-navigation-mobile-icon-color: @app-navigation-element-icon-color;

.app{
    &.app-navigation-mobile-wrapper{
        //background: @app-navigation-mobile-bg;
        .app-navigation-mobile-bg;

        .app-container{
            .transition(all @app-default-transition-speed linear);
        }
    }

    &.app-navigation-mobile-open{
        overflow: hidden;
        height: 100%;

        .app-container{
            width: 100%;
            height: 100% !important;
            overflow: hidden;
            box-shadow:0px 0px 75px 20px rgba(0,0,0,0.5);

            -ms-transform: matrix(0.9, 0, 0, 0.9, 200, 0);
            -webkit-transform: matrix(0.9, 0, 0, 0.9, 200, 0);
            transform: matrix(0.9, 0, 0, 0.9, 200, 0);
        }
        .app-navigation-mobile{
            opacity: 1;
            z-index: 0;
        }
    }

    .app-navigation-mobile{
        position: absolute;
        left: 0px;
        top: 50px;
        width: 200px;
        opacity: 0;
        transition:opacity 0.5s linear;

        > ul{
            float: left;
            width: 100%;
            list-style: none;

            > li{
                float: left;
                width: 100%;
                padding: 10px 15px;
                line-height: 20px;

                a{
                    line-height: 30px;
                    font-size: @app-navigation-mobile-text-size;
                    font-weight: 400;

                    color: @app-navigation-mobile-text-color;
                    text-decoration: none;
                    .transition(color @app-default-transition-speed linear);

                    span{
                        &[class^='icon-']{
                            font-size: 20px;
                            float: left;
                            margin-right: 20px;
                            color: @app-navigation-mobile-icon-color;
                        }
                    }

                    &:hover{
                        color: @app-navigation-mobile-text-color-hover;
                    }
                }
            }
        }
    }


    // Horizontal Navigation
    .horizontal-nav-logo{
        float: left;
        width: 300px;
        padding-left: 30px;
        padding-top: 22px;
        display: block;
        //background: @ink;
        height: calc(100% + 12px);
        margin-top: -12px;
    }

    .app-navigation-horizontal{
        float: left;
        width: 100%;
        background: @white;
        position: relative;

        &.state-open + .app-heading-page{
            margin-top:70px;
        }

        + .app-heading-page{margin-top:20px}

        .menu-collapse{
            padding:16px 10px 10px 30px;

            span{
                color:@orange;
            }

            &:before{
                content:"\2192";
                position: absolute;
                top:16px;
                left:10px;
                color:@orange;

            }
        }

        nav{
            > ul{
                float: left;
                width: 100%;
                margin: 0px;
                list-style: none;
                padding: 10px 0px 0px 10px;
                position: relative;

                > li{
                    display: inline-block;
                    position: relative;
                    float: none;
                    width: initial;

                    > a{
                        display: block;
                        padding: 10px 25px 12px 20px;
                        position:relative;
                        line-height: 20px;
                        text-align: center;
                        color: @ink;
                        text-decoration: none;
                        text-transform: none;
                        font-size: @app-navigation-horizontal-font-size;
                        font-weight: @app-navigation-horizontal-font-weight;
                        border-radius:@app-design-border-radius;
                        .transition(color @app-default-transition-speed linear);


                       svg{
                           line-height: 20px;
                           width: 16px;
                           height: 16px;
                          margin: 0 3px 0 0;
                           position: relative;
                           top: -2px;
                        }

                        span{
                            color:@ink;

                        }
                        span.label{
                            margin: 0;
                            position: absolute;
                            padding: 1px 4px;
                            font-size: .9rem;
                            display: block;
                        }
                        span.empty{display: none !important;}

                        &:hover{
                            color: @ink;
                            background: @misty;
                        }
                    }

                    > ul{
                        position: absolute;
                        width: 200px;
                        left: 0px;
                        top: 100%;
                        z-index: 999;
                        list-style: none;
                        background: @misty;
                        display: none;
                        border-bottom: 1px solid @smoke;
                        padding: 10px 15px;
                        margin-top:-1px;

                        > li{
                            display: block;

                            > a{
                                display: block;
                                color: @app-navigation-horizontal-secondary-color;
                                text-decoration: none;
                                font-size: @app-navigation-horizontal-font-size;
                                font-weight: 500;
                                padding: 10px 15px;
                                margin-right: 0;
                                line-height: 20px;
                                .transition(all @app-default-transition-speed linear);

                                &:hover{
                                    background: @white;
                                }
                            }

                            &.active > a, a.active{
                                background: @white;
                            }
                        }
                    }

                    &.openable{
                        > a{
                            &:after{
                                content: "\25BE";
                                position: absolute;
                                bottom: 2px;
                                right: -4px;
                                padding: 5px 10px;
                                line-height: 30px;
                                width: 40px;
                                text-align: center;
                                transition: all .2s linear;
                            }
                        }

                        &:hover{
                            background: @misty;
                        }
                        &:hover .label{display: none}
                        &:hover ul{
                            display: block;
                        }

                        &.active, &.open{
                            > a{
                                background: @misty;
                                svg{fill:@ink}
                            }
                        }
                    }

                    &.active, &.open{
                        > a{color: @app-navigation-horizontal-color-active;}

                    }

                    &.open{
                        background: @misty;
                        //.border-radiuses(4px, 4px, 0px, 0px);
                        > ul{display: block;}
                    }
                }
            }

        }

        .container{
            &.container-boxed{
                margin: 0px auto;

                > nav{
                    > ul{
                        padding-left: 0px;

                        > li > ul{
                            padding-left: 0px;
                        }
                    }
                }

                &:before{
                    position: absolute;
                    left: 0px;
                    bottom: -1px;
                    height: 61px;
                    width: 100%;
                    background: @app-navigation-horizontal-bg-active;
                    content: " ";
                    border-bottom: 1px solid @app-navigation-horizontal-border;
                }

            }
        }
    }

    // END Horizontal Navigation

}

@media (max-width: @screen-md) {
    .app-navigation{
        .app-navigation-logo{
            display: none;
        }

        &.app-navigation-style-light{
            border-right: 0px;
            border-bottom: 1px solid @smoke;
        }
    }

    .app{
        .app-navigation-horizontal{
            display: none;

            .container{
                &.container-boxed{
                    padding: 0px;
                    &:before{
                        display: none;
                    }
                }
            }

            .menu-collapse{
                top: -10px;
                padding-left: 50px;

                &:before{
                    left: 20px;
                }
            }

            nav{
                > ul{
                    margin: 0px;
                    padding: 0px;

                    > li{
                        width: 100%;
                        border-bottom:solid 1px @cloudy;

                        > a{
                            padding: 12px 20px 12px;
                            width: 100%;
                            text-align: left;

                            svg{
                                display:inline-block;
                                margin-right:10px;
                            }

                            span.label{
                                position: relative;
                                padding: 3px 6px;
                                display: inline;
                                margin-left: 10px;
                                font-size: 1rem;
                            }
                        }

                        &.openable{
                            &.active{

                                > a{
                                    background: @misty;
                                    color: @ink;
                                }
                            }
                            &:hover ul{
                                display: none;
                            }

                            &.open ul{
                                display: block;
                            }
                        }

                        > ul{
                            position: relative;
                            float: left;
                            width: 100%;
                            padding: 5px;
                            border-bottom: none;

                            > li{
                                width: 100%;
                                margin-bottom: 5px;

                                > a{
                                    width: 100%;
                                    padding-left: 45px;
                                }
                            }
                        }
                        &:last-child{
                            margin-bottom: 0px;
                        }
                    }
                }
            }

            &.show{
                display: block;
            }
        }
    }
}
