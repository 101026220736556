.label-build(@name, @background, @color){
    &.@{name}{
        background: @background !important;
        color: @color !important;
    }
}

.label-bordered-build(@name, @border, @color){
    &.@{name}{
        border: 1px solid @border;
        color: @color;

        &.label-ghost{
            .box-shadow(0px 0px 0px 3px fade(@border,15%));
        }

        &[href]:hover{
            background: transparent;
            border-color: darken(@border,10%);
            color:  darken(@color,10%);
        }
    }
}

.badge-style-build(@name,@background, @color){
    &.@{name}{
        background: @background;
        color: @color;

        &[href]:hover{
            background: darken(@background,10%);
            color:  darken(@color,10%);
        }
    }
}
.badge-bordered-build(@name,@background,@color){
    &.@{name}{
        border: 2px solid @background;
        color: @color;
    }
}

.app{
    .label{
        transition:all @app-default-transition-speed linear;
        border-radius:@app-design-border-radius;

        .label-build(label-default, @smoke, @ink);
        .label-build(label-primary, @cloudy, @ink);
        .label-build(label-success, @light-green, @green);
        .label-build(label-info, @light-blue, @blue);
        .label-build(label-warning, @light-orange, @orange);
        .label-build(label-danger, @light-red, @red);


        &.label-rounded{
            border-radius:10px;
            padding-left: 8px;
            padding-right: 8px;
        }
        &.label-ghost{
            margin: 0 0 0 5px;
        }

        .label-primary-success, .label-primary-warning, .label-primary-danger, .label-primary-info{
            font-size: 13px;
            text-transform: none;
        }
        &.label-primary-success{color:#447515;}
        &.label-primary-warning{color:#F3A536;}
        &.label-primary-danger{color:#f04e51;}
        &.label-primary-info{color:#00a6ff;}
        &.label-primary-default{color:#a4a6b0;}

        &.label-primary-success::before,
        &.label-primary-warning::before,
        &.label-primary-danger::before,
        &.label-primary-info::before,
        &.label-primary-default::before{
            content:"\2022";
            font-size: 32px;
            position: relative;
            margin-right: 4px;
            top: 4px;
            line-height: 4px;
        }

    }

    .badge{

        border-radius:@app-design-border-radius;

        .badge-style-build(badge-primary, @silver, @ink);
        .badge-style-build(badge-success, @green, @white);
        .badge-style-build(badge-info, @blue, @white);
        .badge-style-build(badge-warning, @orange, @white);
        .badge-style-build(badge-danger, @red, @white);

        &.badge-bordered{
            background: transparent;
            padding: 2px 7px 3px;

            .badge-bordered-build(badge-default, @smoke, @ink);
            .badge-bordered-build(badge-primary, @cloudy, @ink);
            .badge-bordered-build(badge-success, @green, @green);
            .badge-bordered-build(badge-info, @blue, @blue);
            .badge-bordered-build(badge-warning, @orange, @orange);
            .badge-bordered-build(badge-danger, @red, @red);
        }
    }

    .label-icon{
        line-height: 20px;
        padding: 5px;
        text-align: center;
        min-width: 30px;
        background: @smoke;
        display: inline-block;
        border-radius: 3px;
        transition:all @app-default-transition-speed linear;
        text-decoration: none;

        .badge-style-build(label-icon-primary, @cloudy, @white);
        .badge-style-build(label-icon-success, @green, @white);
        .badge-style-build(label-icon-info, @blue, @white);
        .badge-style-build(label-icon-warning, @orange, @white);
        .badge-style-build(label-icon-danger, @red, @white);

        &.label-icon-bordered{
            background: transparent;
            padding: 4px 5px;

            .label-bordered-build(label-icon-default, @smoke, @ink);
            .label-bordered-build(label-icon-primary, @cloudy, @cloudy);
            .label-bordered-build(label-icon-success, @green, @green);
            .label-bordered-build(label-icon-info, @blue, @blue);
            .label-bordered-build(label-icon-warning, @orange, @orange);
            .label-bordered-build(label-icon-danger, @red, @red);
        }

        &.label-icon-rounded{
            border-radius:50%;
        }
        &.label-icon-sm{
            min-width: 20px;
            padding: 0px;

            &.label-icon-bordered{
                line-height: 18px;
            }
        }
    }
}