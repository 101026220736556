.setup-wizard-controls{

    margin-right:15px;
    text-align: center;

    .dropdown-menu{
        width: 500px;
        margin-left: -238px;
        margin-top: 5px;
        padding: 15px 15px 15px 10px;
    }

    .arrow-up {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid @white;
        position: absolute;
        top: -10px;
        left:50%
    }

    .group {
        margin-top: 15px;
        font-size: 12px;
        font-weight: bold;
        color: @silver;
        border-bottom: solid 2px @smoke;
        text-transform: uppercase;
        letter-spacing: 0.8px;

        .items-count {
            float: right;
            font-weight: normal;
        }

        span {
            text-transform:initial;
            letter-spacing: normal;
        }
    }

    .items-listing {
        padding:0;
        list-style: none;

        li {
            margin-top: 10px;
            line-height: 35px;

            &:before{
                content:"\2192";
                font-size:12px;
                margin-right:6px;
                color:#979797;
            }
        }

        .item {
            font-size: 16px;
            font-weight: 500;
            color: #585d6f;

            &:hover{
                text-decoration: none;
                color: @dark-blue;
            }
        }

        .dismiss {
            cursor: pointer;
            float: right;
            font-size: 12px;
            color: @silver;

            .icon {
                fill: @silver;
                width: 13px;
                margin-top:-1px;
            }

            &:hover {
                text-decoration: none;
                color: @mid-red;

                > .icon {
                    fill: @mid-red;
                }
            }
        }
    }

    .loader-border {
        height: 8px;
        border-radius: 4px;
        background-color: @smoke;
        position:relative;
        overflow: visible;

        .loader-content {
            height: 8px;
            border-radius: 4px;
            background-color: @dark-blue;
            transition:width 1s;
            width: 0;
            position:relative;
            overflow: visible;
        }
    }

    #setup_progress_btn {
        background-color: transparent;
        border: none;

        .circle {
            border: solid 0.1em rgba(255, 255, 255, 0.4);
            position: relative;
            font-size: 40px;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            float: left;
            margin: 0 0.1em 0.1em 0;

            .slice {
                position: absolute;
                left: -0.1em;
                top: -0.1em;
                width: 1em;
                height: 1em;
                /*clip: rect(0em, 1em, 1em, 0.5em);*/
                clip: rect(auto, auto, auto, auto);
            }

            .bar {
                position: absolute;
                border: solid 0.1em @white;
                width: 1em;
                height: 1em;
                clip: rect(0em, 0.5em, 1em, 0em);
                border-radius: 50%;
                transform: rotate(244deg);
            }

            .fill {
                position: absolute;
                border: solid 0.1em @white;
                width: 1em;
                height: 1em;
                clip: rect(0em, 0.5em, 1em, 0em);
                border-radius: 50%;
                transform: rotate(180deg);
            }

            span{
                position: absolute;
                z-index: 1;
                left: -0.2em;
                top: -0.1em;
                width: 2.5em;
                line-height: 2.5em;
                font-size: 0.4em;
                color: @white;
            }
        }
    }

}


.wizard-completion-status-text{
    display: block;
    position: relative;
    top: -11px;
    left: -8px;
    letter-spacing: 0.7px;
    color: @white;
    font-size: 12px;
}

.pin {
    font-size: 15px;
    width:38px;
    height: 38px;
    color:@white;
    line-height: 2.5em;
    text-align: center;
    position: absolute;
    right: -19px;
    bottom: 14px;
    z-index:2;
    opacity:.9;

    &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #1d78f0;
        -webkit-transform: rotateZ(45deg);
        transform: rotateZ(45deg);
        border-radius: 50% 50% 0 50%;
    }
}

.wizard-content{
    position:relative;
    overflow-y: scroll;
    height: 400px;
    padding: 10px 25px 10px 10px
}
.completion{
    padding:10px 5px;

    .close{
        opacity:0.8;
    }
}


#setup_wizard_dropdown{
    top:-9px;
    cursor: pointer;
    position: relative;
    z-index:1001;
}

.wizard-completion-status-text {
    display: block;
    position: relative;
    top: -5px;
    letter-spacing: 0.7px;
    color: @white;
    font-size: 12px;
    margin-left: -40px;
    width: 80px;
    left: 50%;
}

#complete-user-profile-mode-wrapper{
    display:none;
}

@media screen and (max-width: 768px) {
    .setup-wizard-controls {
        display: none;
    }
}
