.container-tab {
    background:@white;
    box-shadow: @block-box-shadow;
    border-radius: @app-design-border-radius;
}

.nav{
    
    > li > a{
        padding: 15px 45px;
        border:none;
        transition: background @app-default-transition-speed linear;
        
        &:hover{
            border-bottom:solid 5px @smoke;
            background-color:@misty;
        } 
    }
    
    &.nav-tabs{
        > li{
            width: auto;
            margin-bottom:0px;
            
            > a{
                font-size:1.2em;
                color:@pewter;
                border-bottom: solid 5px transparent;
                font-weight: 500;

                svg{
                    margin-right:10px;
                    //fill:@pewter;
                }

                &:hover{
                    border-color:@smoke;
                }
            }
            
            &.active{
                > a{
                    background-color: @white;
                    border:none;
                    border-bottom:solid 5px @dark-blue;
                    color:@dark-blue;
                }
            }
        }
    }

    &.nav-tabs-rounded {
        border: 1px solid @smoke;
        display: inline-block;
        border-radius: 30px;
        margin: 0px auto;
        padding:2px;

        >li>a {
            background-color: @white;
            border: none;
            padding:10px 30px;
            border-radius: 30px;
            font-weight: 500;
            font-size:1.1em;

            &:hover{
                background-color: @misty;
            }
        }

        >li.active>a{

            background-color: #1d78f0;
            color: @white;
            border-bottom: 0;

            &:hover{
                background-color: #1d78f0;
                color: @white;
                border-bottom: 0;
            }
        }
    }

    &.nav-pills{
        > li{
            width: auto;
            margin-right: 5px;
            
            > a{                
                color: @ink;
                background: transparent;
                border: 1px solid transparent;
                font-weight: 600;
                
                &:hover{                    
                    background: @misty;
                }
            }
            
            &.active{
                > a{
                    background: @ink;
                    color: @cloudy;
                }
            }
        }
        
        &.nav-pills-bordered{
            > li{
                > a{
                    &:hover{
                        border: 1px solid transparent;
                    }
                }
                &.active{
                    > a{
                        background: transparent;
                        border: 1px solid @ink;
                        color: @ink;
                    }
                }
            }
        }
    }
    
    &.nav-justified{
        > li{
            width: 1%;
        }
    } 
}
.tab-content{
    float: left;
    width: 100%;
    margin-top: 0px;
    padding: 15px;
    
    &.tab-content-bordered{
        background: @white;
        border: 1px solid @smoke;
        border-top: 0px;
        border-radius : 0px 0px @app-design-border-radius @app-design-border-radius;
    }
}
@media (max-width: @screen-md) { 
    .nav.nav-justified > li{
        width: 100%;
        
        > a{
            border: 1px solid @smoke;
        }
    }
    .tab-content.tab-content-bordered{
        border:  1px solid @smoke;
    }
}

/****** To Convert into LESS *****/

.tab-compact{
    &.nav.nav-tabs>li>a{
        font-weight: 500;
        padding:15px 20px;
        border-right: solid 1px @cloudy;
        margin-right:0;

        svg{

            display: block;
            margin: 0px auto 10px auto;
        }

        &.client-details{
            padding:15px 30px;
        }
    }

    &.tab-dashboard{
        border:none;

        &.nav.nav-tabs>li{
            width: 20%;
            text-align: center;
            display: table;

            >a{
                padding:0;
                border-bottom: solid 1px @cloudy !important;
                height: 150px;
                display: table-cell;
                vertical-align: middle;
            }
        }

        &:nth-child(4n+4)>a{
            border-right:none;
        }
    }
}

.tab-condensed.nav.nav-tabs>li>a{
    padding:15px 30px;
}

.tab-semi-compact.nav.nav-tabs>li>a{
    padding: 15px 30px;
}

.padding-sides-30.nav>li>a{
    padding-left:30px;
    padding-right:30px;
}