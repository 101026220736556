.app{

    .app-heading .heading-elements{
        float:right;
        width:auto;
    }
    // universal block container
    .block{
        position: relative;
        float: left;
        width: 100%;
        padding:15px;
        margin-bottom: 15px;
        background: @white;
        border:none;
        box-shadow: 0 1px 4px 0 rgba(0,0,0,.1);
        border-radius:@app-design-border-radius;
        transition:all .5s ease;

        .app-heading{
            background: transparent;
        }

        &.no-padding .pagination{
            margin:16px 14px 9px 0!important;
        }

        > .app-heading{
            margin-top: 20px; //mb better 30px
            margin-bottom: 15px;
            padding: 15px 0px;

            &:first-child{
                margin-top: 0px;
            }
        }

        > .block-heading{
            float: left;
            width: 100%;
            margin-bottom: 15px;
            .border-radiuses(@app-design-border-radius, @app-design-border-radius, 0px, 0px);
        }

        > .block-content{
            float: left;
            width: 100%;
            margin-bottom: 15px;
        }

        > .block-footer{
            float: left;
            width: 100%;
            .border-radiuses(0px, 0px, @app-design-border-radius, @app-design-border-radius);
        }

        &.block-condensed{
            padding: 0px;

            > .app-heading{
                padding: 20px 20px 15px;
            }
            > .block-heading{
                padding: 10px 20px;
                background: @misty;
                border-bottom: 1px solid @smoke;

                > .app-heading{
                    padding: 10px 0px;
                    background: transparent;
                }
            }

            > .block-content{
                padding: 0px 20px;
            }

            > .block-footer{
                background: @misty;
                border-top: 1px solid @smoke;
                padding: 20px;
            }
        }


        &.block-toggled{
            padding-bottom: 10px;

            > .app-heading{
                margin-bottom: 0px;
            }
            > .block-content,
            > .block-footer{
                display: none;
            }
        }

        &.block-expanded{
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0px;
            top: 0px;
            z-index: 1050;
            border: 0px;
            .border-radius(0px);
        }

        &.block-arrow-top{
            position: relative;

            &:after, &:before {
                bottom: 100%;
                left: 30px;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
            }

            &:after {
                    border-color: rgba(136, 183, 213, 0);
                    border-bottom-color: @white;
                    border-width: 10px;
                    margin-left: -10px;
            }
            &:before {
                    border-color: rgba(194, 225, 245, 0);
                    border-bottom-color: @smoke;
                    border-width: 11px;
                    margin-left: -11px;
            }
        }

        > .block-loading-layer{
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background: rgba(255,255,255,0.5);

            .app-spinner{
                position: absolute;
                left: 50%;
                top: 50%;
                margin: -10px 0px 0px -10px;
            }
        }


        &.block-alt{
            box-shadow:0 2px 14px 0 rgba(193, 205, 227, 0.5);
            text-align: center; padding:0;

            .block-title{
                padding:30px;
            }

            .block-footer{
                background-color: #f6f8fa;
                text-align: center;
                border-top:solid 1px #e3e8f1;

                &:hover{
                    background-color: @green;
                }
            }

            h3{
                font-size: 20px;
                font-weight:500;
            }

            a {
                display: block;
                color: @ink;
                font-size: 16px;
                padding: 15px;
            }

            p {
                float: none;
                font-size: 15px;
                color: @pewter;
            }

            .block-footer:hover a{
                text-decoration: none;
                color:@white;
            }

            svg {
                width:40px;
                height:30px;
                margin-bottom:20px;
            }
        }

    }
    // end universal block container


    // bootstrap panel

    .panel{

        box-shadow: 0 0 14px 4px rgba(0, 0, 0, 0.1);
        > .panel-heading,
        > .panel-body,
        > .panel-footer{
            float: left;
            width: 100%;
        }

        .panel-elements{
            float: left;
            margin-left: -5px;

            .btn{
                float: left;
            }

            .form-group, .input-group{
                margin-bottom: 0px;
            }

            &.pull-right{
                margin-left: 0px;
                margin-right: -5px;
            }
        }

        .panel-collapse{
            float: left;
            width: 100%;
        }


        &.panel-toggled{
            > div:not(.panel-heading){
                display: none;
            }
        }

        &.panel-expanded{
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0px;
            top: 0px;
            z-index: 1050;
            border: 0px;
            .border-radius(0px);
        }
    }
    .panel-group{
        .panel > .panel-heading{
            padding: 5px 15px;

            .panel-elements{
                margin-left: -10px;

                &.pull-right{
                    margin-right: -10px;
                }
            }
        }
    }
    // end bootstrap panel
}


// Info panel Data

.info-panel-data{
    list-style:none;


    li{

        font-size:14px;
        margin-bottom:10px;
        clear:both;
        float:left;
    }

    .inner-title{
        font-size:12px;
        width: 80px;
        float: left;
        vertical-align: top;
    }

    .inner-desc{float:left}

    .inner-block {
        float:left;
        margin-right: 20px;
        margin-bottom: 10px;
    }

    .inner-block .inner-title{
        font-size: 14px;
    }

    .label{float:left}

}


/**** Flex Grids ****/

.flex-grid{
    display:flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    .block{

        &:hover{
            transform: translateY(4px);

            .block-icon{
                background: @light-green;
            }
        }

        &.block-alt svg{
            margin:0;
        }

        a.flex-grid-card-link{
            padding:0 !important;
            width: 100%;
            height:100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            text-align: left;
        }
    }

    .block-icon{
        padding-left: 15px;
        padding-right: 15px;
        border-right: @smoke 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

.flex-grid.one-thirds > div {
    flex: 0 0 calc(33.333333% - 15px);
    margin-right:15px;
}

/* IE11 only */
@media all and (-ms-high-contrast:none)
{
    *::-ms-backdrop, .flex-grid.one-thirds > div {
        flex: 0 0 32%;
    }
}
