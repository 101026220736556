@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress-wrap {
  display: flex;
  align-items: center;

  .progress {
    margin-bottom: 0;
    flex: 1 0 auto;
    flex-basis: 75px;
  }

  .progress-right {
    margin-left: 10px;
  }
}

.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(@black, 0.1);
}

.progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  box-shadow: inset 0 -1px 0 rgba(@black, 0.15);
  transition: width 0.6s ease;
}

.progress-striped .progress-bar,
.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(@white, 0.15) 25%, transparent 25%, transparent 50%, rgba(@white, 0.15) 50%, rgba(@white, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
}

.progress.active .progress-bar,
.progress-bar.active {
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-bar-success {
  background-color: #5cb85c;
}

.progress-striped .progress-bar-success {
  background-image: linear-gradient(45deg, rgba(@white, 0.15) 25%, transparent 25%, transparent 50%, rgba(@white, 0.15) 50%, rgba(@white, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-info {
  background-color: #5bc0de;
}

.progress-striped .progress-bar-info {
  background-image: linear-gradient(45deg, rgba(@white, 0.15) 25%, transparent 25%, transparent 50%, rgba(@white, 0.15) 50%, rgba(@white, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-warning {
  background-color: #f0ad4e;
}

.progress-striped .progress-bar-warning {
  background-image: linear-gradient(45deg, rgba(@white, 0.15) 25%, transparent 25%, transparent 50%, rgba(@white, 0.15) 50%, rgba(@white, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-danger {
  background-color: #d9534f;
}

.progress-striped .progress-bar-danger {
  background-image: linear-gradient(45deg, rgba(@white, 0.15) 25%, transparent 25%, transparent 50%, rgba(@white, 0.15) 50%, rgba(@white, 0.15) 75%, transparent 75%, transparent);
}